body.sovm {
    .header {
        background: lighten($sovm, 40%);

        &__insta {
            border-left-color: lighten($sovm, 35%);

            .icon {
                color: lighten($sovm, 20%);
            }
        } // &__insta

        &-clients {
            border-right-color: lighten($sovm, 35%);

            &__head,
            &__sub {
                color: lighten($sovm, 10%);
            } // &__head
        } // &-clients

        &-menu {
            border-top-color: lighten($sovm, 35%);
            transition: $transition-base;
        } // &-menu

        &__btn {
            border-color: $sovm;
            color: $sovm;

            &:before {
                background: linear-gradient(
                    -92deg,
                    opacity($white, 0.7),
                    opacity($white, 0.7)
                );
            }

            &:hover {
                color: $white;
                border-color: $purple;
                background: $purple;
            }
        } // &__btn

        @include media-breakpoint-down(md) {
            &.open {
                background: $white;
                box-shadow: 0 1rem 1.5rem -0.75rem opacity(darken($sovm, 10%), 0.6);

                .header {
                    &-logo {
                        .light {
                            fill: $gray-200;
                        } // .light

                    } // &-logo

                    &__insta {
                        svg {
                            fill: $gray-300;
                        } // svg

                        &:hover {
                            svg {
                                fill: $sovm;
                            }
                        }
                    } // &__insta

                    &-menu {
                        border-top-color: $gray-200;
                    } // &-menu
                }
            } // &.open
        }

    } // .header
} // body.money

.sovm {
    &__head {
        display: block;

        color: $sovm;
        font-size: $font-size-lg * 1.5;
        font-weight: $font-weight-bold;
        line-height: 1.2;

        margin: 0;
        padding: 0;
        margin-bottom: 0.5rem;
    } // &__head

    &__img {
        @include no-select;

        display: block;
        height: 4rem;
        width: auto;
        margin-left: 0;
        margin-bottom: 1.5rem;
    } // &__img

    &__lead {
        display: block;
        position: relative;
        z-index: 1;

        color: $black;
        font-size: $font-size-base * 1.125;
        font-weight: $font-weight-base;
        line-height: 1.5;

        margin: 0;
        padding: 0;

        a {
            color: $sovm;

            border-bottom: 1px solid opacity($sovm, 0);

            transition: $transition-base;

            &:hover {
                cursor: pointer;

                text-decoration: none;

                color: $purple;
                border-bottom: 1px solid opacity($purple, 0.23);
            }
        }
    } // &__lead

    &-tabs {
        background: $white;
        border-radius: 50rem;
        border: none;
        padding: 0.35rem;

        margin-top: 1rem;

        .nav-item {
            margin-top: 0;
            margin-bottom: 0;
        }

        .nav-link {
            color: $sovm;
            background-color: transparent;
            border: none;
            border-radius: 50rem;

            &.active {
                color: $white;
                background-color: $sovm;
            }
        }
    }

    &-hero {
        background: linear-gradient(lighten($sovm, 40%), $white);

        &__wrap {
            @include make-container;
            @include make-container-max-widths;

            padding-top: 6rem;
            padding-bottom: 3rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        } // &__wrap

        &__head {
            display: block;

            color: $black;
            font-size: $font-size-lg * 4;
            font-weight: $font-weight-bold;
            line-height: 1;

            margin: 0;
            padding: 0;

            margin-bottom: 1rem;

            @include media-breakpoint-down(md) {
                font-size: $font-size-lg * 3;
            }
        } // &__head

        &__sub {
            display: block;

            color: $sovm;
            font-size: $font-size-lg * 1.5;
            font-weight: $font-weight-base;
            line-height: 1;

            margin: 0;
            padding: 0;
        } // &__sub

        &__img {
            @include no-select;

            display: block;
            max-width: 100%;

            margin-bottom: 2rem;
        }
    } // &-hero

    &-count {
        margin-top: 4rem;
        margin-bottom: 4rem;
    } // &-count

    &-div {
        background: $white;
        position: relative;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: baseline;

        padding: 2.5rem 1.5rem;

        border: 1px solid lighten($sovm, 30%);
        border-radius: 0.5rem;

        &--center {
            align-items: center;
        }

        &:after {
            content: '';

            position: absolute;
            left: 0;
            top: 0;

            background: linear-gradient(-45deg, opacity($sovm, 0.05), opacity($sovm, 0));

            z-index: 0;
            width: 100%;
            height: 100%;
        }
    } // &-div

    &-form {
        background: $sovm;

        background: linear-gradient(45deg, $sovm, #2a4847);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        padding: 2rem 2.5rem;

        border-radius: 0.75rem;

        margin-bottom: 0.5rem;

        @include media-breakpoint-down(md) {
            padding: 2rem 1rem;
            text-align: center;
        }

        .sovm__sub {
            color: opacity($white, 0.8);
            display: block;
            text-align: center;

            font-size: $font-size-lg;
            font-weight: $font-weight-base;
            line-height: 1.2;

            margin: 0;
            padding: 0;

            margin-bottom: 2rem;

            max-width: 80%;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

        .sovm__head {
            color: $white;
            font-size: $font-size-lg * 2;
            margin-bottom: 0.25rem;

            @include media-breakpoint-down(md) {
                font-size: $font-size-lg * 1.5;
            }
        } // .money__head

        &__inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            margin-bottom: 2rem;
        } // &__inner

        &__bot {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin-bottom: 2rem;

            .sovm-form__item {
                width: 92%;
                margin-right: 0;
                margin-bottom: 1rem;
            }
        } // &__inner

        &__item {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: flex-sovmt;

            width: 10rem;

            margin-right: 1rem;

            @include media-breakpoint-down(md) {
                width: 5rem;
            }

            &-last {
                width: 12rem;
                margin-right: 0;

                @include media-breakpoint-down(md) {
                    width: 7rem;
                }
            }
        } // &__item

        &__label {
            display: block;

            color: lighten($sovm, 20%);
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1;
            margin: 0;
            padding: 0;

            margin-bottom: 0.5rem;
        } // &__label

        &__input {
            display: block;

            position: relative;
            max-width: 100%;

            color: $black;
            font-size: $font-size-lg * 3;
            font-weight: $font-weight-base;
            line-height: 1;

            background: $white;
            border: 2px solid opacity($yellow, 0);
            border-radius: 0.25rem;

            margin: 0;
            padding: 0;

            padding: 1rem 0.75rem;
            text-align: center;

            transition: $transition-base;

            @include media-breakpoint-down(md) {
                font-size: $font-size-lg * 1.5;
            }

            &:focus,
            &:active {
                outline: none;
                border: 2px solid $yellow;
            }
        } // &__input

        &__btn {
            display: block;
            position: relative;
            overflow: hidden;

            color: $black;
            background: #ffe600;
            border: none;

            box-shadow: 0 1rem 1rem -0.5rem opacity($black, 0.3);

            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin: 0;
            padding: 1rem 1.5rem;
            border-radius: 25rem;

            margin-bottom: 1rem;

            transition: $transition-base;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                background: linear-gradient(
                    -92deg,
                    opacity($white, 0.7),
                    opacity($white, 0.7)
                );

                width: 3rem;
                height: 100%;

                transform: skewX(-42deg) translateX(-10%);
                z-index: 1;
                will-change: transform;

                opacity: 0;

                transition: all 0.3s ease;
            } // &:before

            &:hover {
                color: $black;
                text-decoration: none;
                transform: translateY(-10px);

                box-shadow: 0 2.5rem 2rem -1rem opacity(darken($sovm, 30%), 0.6);

                &:before {
                    transform: skewX(-42deg) translateX(510%);
                    opacity: 1;
                } // &:before
            }

            &:focus,
            &:active {
                outline: none;
            }
        }

        &__help {
            display: block;

            color: lighten($sovm, 20%);
            font-size: $font-size-sm;
            font-weight: $font-weight-base;
            line-height: 1.2;
            margin: 0 auto;
            text-align: center;
            padding: 0;

            a {
                color: lighten($sovm, 20%);
                text-decoration: none;

                border-bottom: 1px solid opacity(lighten($sovm, 20%), 0.23);
                transition: $transition-base;

                &:hover {
                    text-decoration: none;
                    color: $white;
                    border-bottom-color: opacity($white, 0.23);
                }
            }
        } // &__help
    } // &-form

    &-contact {
        background: lighten($sovm, 42%);

        border-radius: 0.5rem;

        padding: 2rem 1.5rem;
    }
} // .sovm

.sovm-result {

    padding-bottom: 4rem;

    &-hero {
        background: $sovm;
        padding-top: 4rem;

        &__back {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-sovmt;

            &:hover {
                text-decoration: none;
                .sovm-result-hero__arr {
                    fill: lighten($sovm, 20%);
                }
                .sovm-result-hero__link {
                    text-decoration: none;
                    color: lighten($sovm, 20%);
                }
            }
        } // &__back

        &__arr {
            @include no-select;

            display: block;

            height: 1rem;
            width: auto;

            margin-right: 0.5rem;

            fill: $white;
            transition: $transition-base;
        } // &__arr

        &__link {
            display: block;

            text-decoration: none;

            color: $white;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1;

            margin: 0;
            padding: 0;
            transition: $transition-base;
        }

        &__logo {
            display: block;
            width: 10rem;
            height: auto;

            margin: 0 auto;

            @include media-breakpoint-down(md) {
                width: 8rem;
            }

            @include media-breakpoint-down(lg) {
                margin-left: auto;
                margin-right: 0;
            }
        } // &__img

        &__top {
            display: block;

            background: $white;

            width: 100%;

            margin: 0;
            padding: 0;

            color: $black;
            font-size: $font-size-lg * 1.125;
            font-weight: $font-weight-base;
            line-height: 1;
            text-align: center;

            padding: 2rem 1rem;

            margin-top: 2rem;

            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        } // &__top

    } // &-hero

    &__numbers {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;

        padding-top: 2rem;
        margin-bottom: 2rem;

        flex-wrap: wrap;

        .letter,
        .num {
            display: block;

            font-size: $font-size-lg * 1.5;
            font-weight: $font-weight-base;
            line-height: 1.2;

            margin: 0;
            padding: 0;
        }

        .letter {
            color: $gray-500;
            margin-right: 0.25rem;
        }

        .num {
            color: $sovm;
            font-weight: $font-weight-bold;

            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    } // &__numbers

    &__sub {
        display: block;

        color: $black;
        font-size: $font-size-base * 1.25;
        font-weight: $font-weight-base;
        line-height: 1.6;

        margin: 0;
        padding: 0;

        margin-bottom: 1rem;

        strong {
            font-weight: $font-weight-bold;
            color: $sovm;
        }

    } // &__sub

    &__list {
        color: $black;
        font-size: $font-size-base * 1.25;
        font-weight: $font-weight-base;
        line-height: 1.6;

        margin-bottom: 1rem;
    } // &__list

    &__group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        margin-left: auto;
        margin-right: auto;

        &:last-child {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    } // &__group

    &__btn {
        display: flex;
        align-items: center;

        position: relative;
        overflow: hidden;

        color: $sovm;
        background: $white;
        border: 2px solid lighten($sovm, 30%);

        box-shadow: 0 0 0 0 opacity($sovm, 0);

        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        line-height: 1;
        margin: 0;
        padding: 0.5rem 1.25rem;
        border-radius: 50rem;

        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;

        transition: $transition-base;

        svg {
            @include no-select;

            display: block;

            height: 1.5rem;
            width: auto;

            padding: 0;
            margin: 0;

            margin-right: 0.5rem;

            fill: $sovm;
        } // svg

        &:before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            background: linear-gradient(
                -92deg,
                opacity($white, 0.7),
                opacity($white, 0.7)
            );

            width: 3rem;
            height: 100%;

            transform: skewX(-42deg) translateX(-10%);
            z-index: 1;
            will-change: transform;

            opacity: 0;

            transition: all 0.3s ease;
        } // &:before

        &:hover {
            color: $white;
            background: $sovm;
            border-color: $sovm;

            text-decoration: none;
            transform: translateY(-4px);

            box-shadow: 0 1rem 2rem -1rem opacity($sovm, 0.6);

            svg {
                fill: lighten($sovm, 20%);
            }

            &:before {
                transform: skewX(-42deg) translateX(510%);
                opacity: 1;
            } // &:before
        }

        &:focus,
        &:active {
            outline: none;
        }
    } // &__btn

    &__step {
        margin-bottom: 1rem;

        border-bottom: 1px solid $gray-200;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .step {
            background: $white;

            display: inline-block;
            color: $sovm;
            border: 2px solid lighten($sovm, 30%);

            border-radius: 50rem;

            padding: 0.25rem 0.75rem;

            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1.2;

            margin-bottom: 1rem;
        }

        .sovm-result__sub {
            font-size: $font-size-base * 1.125;
        }
    }

    &__img {
        @include no-select;

        display: block;

        max-width: 100%;
        height: auto;

        padding: 1rem;
    }

    .video {
        display: block;

        margin: 0 auto;
    }
} // .sovm-result