//
// Fonts
//

@font-face {
    font-family: 'Futura New';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/FuturaNew-Light.woff2') format('woff2'), url('../fonts/FuturaNew-Light.woff') format('woff');
}
@font-face {
    font-family: 'Futura New';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/FuturaNew-Regular.woff2') format('woff2'), url('../fonts/FuturaNew-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Futura New';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/FuturaNew-Medium.woff2') format('woff2'), url('../fonts/FuturaNew-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Futura New';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/FuturaNew-Bold.woff2') format('woff2'), url('../fonts/FuturaNew-Bold.woff') format('woff');
}
