.header {

    transition: $transition-base;

    &__wrap {
        @include make-container;
        @include make-container-max-widths;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    } // &__wrap

    &-logo {
        display: block;
        text-decoration: none;
        border: none;
        outline: 0;

        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: $transition-base;

        &:hover {
            .header-logo__img {
                fill: $purple;
            }
        }

        @include media-breakpoint-down(md) {
            order: 1;
        }

        &.is-active {
            @include no-select;
        }

        &__img {
            display: block;
            width: 10rem;
            height: auto;

            fill: $black;
            transition: $transition-base;

            @include media-breakpoint-down(md) {
                width: 8rem;
            }
        } // &__img
    } // &-logo

    &__training {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        background: #6d597a;
        position: relative;

        // #6d597a, #f07167

        padding: 0.5rem 0.75rem;
        border-radius: 0.25rem;

        margin-left: 2rem;
        transition: $transition-base;

        .sub {
            display: block;

            color: opacity($white, 0.5);
            font-size: 0.85rem;
            font-weight: 400;

            line-height: 1;
            margin: 0;
            padding: 0;

            margin-bottom: 0.125rem;

            transition: $transition-base;
        } // .sub

        .text {
            display: block;

            font-size: 1rem;
            font-weight: 500;

            color: $white;
            line-height: 1;
            margin: 0;
            padding: 0;

            transition: $transition-base;
        } // .text

        &:hover {
            background: opacity(#6d597a, 0.75);
            text-decoration: none;
            transform: translateY(-3px);
            box-shadow: 0 1rem 1.5rem -0.75rem opacity(darken(#6d597a, 10%), 0.6);
        }

        @include media-breakpoint-down(lg) {
            order: 10;
            flex: 1;
            min-width: 100%;
            margin-left: 0;
            margin-top: 1rem;
        };
    } // &__training

    &-toggler {
        @include media-breakpoint-down(md) {
            display: block;
            order: 3;
        }

        position: relative;
        display: none;

        width: 3rem;
        height: 3rem;

        transition: $transition-base;

        margin-left: auto;

        &__toggle {
            position: relative;
            display: block;
            height: 100%;
            width: 100%;

            &:before,
            &:after {
                content: '';
                position: absolute;
                background: $black;
                height: 3px;
                width: 2rem;
                transition: $transition-base;
                z-index: 9999;
                left: .5rem;

                transform-origin: 50% 50%;
            }

            &:before {
                top: 1rem;
            }

            &:after {
                bottom: 1rem;
            }

        } // &__toggle

    } // &-toggler

    &__insta {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        margin-left: 1rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-left: 1px solid $gray-400;

        text-decoration: none;

        transition: $transition-base;

        position: relative;

        @include media-breakpoint-down(md) {

            border: none;

            margin-right: auto;

            order: 2;
        }

        .text {
            color: $black;
            transition: $transition-base;

            font-size: $font-size-base * 1.125;
            font-weight: $font-weight-base;
            line-height: 1;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .icon {
            display: block;

            font-size: 1.5rem;

            margin-right: 0.5rem;
            margin-bottom: -0.125rem;

            color: $purple;
            transition: $transition-base;
        } // svg

        &:hover {
            text-decoration: none;

            .text {
                color: $purple;
            }

            .icon {
                color: $purple;
            } // svg

        } // &:hover
    } // &__insta

    &-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        width: 100%;

        border-top: 1px solid $gray-100;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            order: 3;
            display: none;
        }

        &__link {
            display: block;
            color: $black;
            font-size: $font-size-base * 1.125;
            font-weight: $font-weight-medium;
            padding: 1.125rem 1.25rem 1.25rem 1.25rem;
            letter-spacing: 0.75px;
            line-height: 1;

            transition: $transition-base;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &.is-active {
                pointer-events: none;
                color: lighten($black, 20%);
            }

            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
            };

            svg {
                display: inline-block;
                height: 2rem;
                width: auto;
            }

            &.is-active {
                color: $gray-500;
                pointer-events: none;
                position: relative;
            }

            &.is-disabled {
                color: opacity($black, .1);
                pointer-events: none;
            }

            &:hover {
                text-decoration: none;
                color: $purple;
            }
        } // &__link

        &__badge {
            display: inline;

            background: $pink;
            color: $white;

            font-size: 0.5rem;
            font-weight: $font-weight-base;
            line-height: 1;

            padding: 0.125rem 0.375rem;
            border-radius: 50rem;

            margin: 0;
            position: relative;
            top: -0.125rem;
            text-transform: uppercase;
        } // &__badge

    } // &-menu

    &__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        position: relative;
        overflow: hidden;

        color: $purple;
        text-decoration: none;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        line-height: 1;

        background: transparent;
        border: 2px solid $purple;
        padding: 0.75rem 1.25rem;
        border-radius: 0.5rem;

        transition: $transition-base;

        &:before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            background: linear-gradient(
                -92deg,
                opacity(lighten($purple, 20%), 0.7),
                opacity(lighten($purple, 20%), 0.7)
            );

            width: 3rem;
            height: 100%;

            transform: skewX(-42deg) translateX(-10%);
            z-index: 1;
            will-change: transform;

            opacity: 0;

            transition: all 0.3s ease;
        } // &:before

        &:hover {
            text-decoration: none;

            color: $white;
            background: $purple;

            box-shadow: 0 1rem 1.5rem -0.75rem opacity(darken($purple, 10%), 0.6);

            &:before {
                transform: skewX(-42deg) translateX(410%);
                opacity: 1;
            } // &:before
        }

        @include media-breakpoint-down(md) {
            display: none;
            order: 5;

            margin-left: auto;
            margin-right: auto;

            margin-bottom: 1rem;

            width: 100%;
            text-align: center;

            &:hover {
                &:before {
                    transform: skewX(-42deg) translateX(810%);
                }
            }
        }

    } // &__btn

    &-clients {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;

        border-right: 1px solid $gray-200;

        margin-left: auto;
        margin-right: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &__head {
            display: block;

            color: $gray-600;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            margin: 0;
            padding: 0;
            line-height: 1;
        } // &__head

        &__sub {
            display: block;

            color: $gray-600;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            margin: 0;
            padding: 0;
            line-height: 1;
        } // &__sub
    } // &-clients

    &.open {
        .header {
            &-toggler {
                &__toggle {
                    &:before {
                        top: 50%;
                        transform: rotate(45deg) translateY(-50%);
                    } // &:before
                    &:after {
                        bottom: 50%;
                        transform: rotate(-45deg) translateY(50%);
                    } // &:after
                } // &__toggle
            } // &-toggler

            &-menu {
                display: flex;
            } // &-menu

            &__btn {
                display: block;
            } // &__btn

            &__insta {
                display: flex;
            } // &__insta
        } // &.header
    } // &.open

} // .header