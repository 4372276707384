.training {
    // #6d597a, #f07167
    //
    .hero {
        background: linear-gradient(90deg, #f8edeb, opacity(#f07167, 0.25));
        border-bottom: 1px solid #f8edeb;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(lg) {
            margin-top: 1rem;
        };

        .im {
            display: block;

            position: absolute;
            right: 0;
            bottom: 0;

            height: 80%;
            width: auto;
            user-select: none;
            pointer-events: none;

            @include media-breakpoint-down(lg) {
                position: relative;

                width: 100%;
                height: 100%;

                margin-left: auto;
                margin-right: auto;
            };
        }

        .headline {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;

            padding-top: 10rem;
            padding-bottom: 10rem;

            position: relative;
            z-index: 2;

            @include media-breakpoint-down(md) {
                padding-bottom: 2rem;
                padding-top: 4rem;
            };

            .sub {
                display: block;

                color: #6d597a;
                font-size: 0.8rem;
                font-weight: 500;
                line-height: 1;

                margin: 0;
                padding: 0.5rem 1rem;

                border-radius: 50rem;
                text-transform: uppercase;
                letter-spacing: 2px;

                border: 1px solid opacity(#6d597a, 0.25);

                margin-bottom: 1rem;
            } // .sub

            .head {
                display: block;

                color: #6d597a;
                font-size: 5rem;
                font-weight: 700;
                line-height: 1;
                text-transform: uppercase;
                letter-spacing: 1px;

                background: linear-gradient(135deg, #6d597a, #f07167);
                background-clip: text;
                -webkit-text-fill-color: transparent;

                margin: 0;
                padding: 0;

                margin-bottom: 1rem;

                @include media-breakpoint-down(xl) {
                    font-size: 5vw;
                };

                @include media-breakpoint-down(md) {
                    font-size: 2.5rem;
                };
            } // .head

            .text {
                display: block;

                color: #af6570;
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1;

                margin: 0;
                padding: 0;
            } // .text
        } // .headline
    } // .hero

    .about {
        padding-top: 6rem;
        padding-bottom: 6rem;

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 1rem;
        };

        .item {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;

            border: 1px solid opacity(#f07167, 0.125);
            box-shadow: 0 0.5rem 2rem -0.5rem opacity(#6d597a, 0.25);
            border-radius: 0.5rem;

            padding: 1.75rem 1.5rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            };

            .head {
                display: block;

                color: #735d78;
                font-size: 1.75rem;
                font-weight: 600;
                line-height: 1.75rem;

                margin: 0;
                padding: 0;

                margin-bottom: 0.5rem;
            } // .head

            .text {
                display: block;

                color: opacity(#735d78, 0.75);
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.375rem;

                margin: 0;
                padding: 0;
            } // .text
        } // .item
    } // .about

    .todo {
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-down(md) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        };

        &-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .head {
                display: inline-block;

                color: #6d597a;
                font-size: 3rem;
                font-weight: 500;
                line-height: 3.25rem;
                text-align: center;

                margin-bottom: 4rem;

                position: relative;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    font-size: 1.75rem;
                    line-height: 2rem;

                    margin-bottom: 2rem;
                };

                &:after {
                    content: '';

                    display: inline-block;

                    height: 0.5rem;
                    width: 100%;

                    position: absolute;
                    z-index: -1;

                    bottom: 0;
                    left: 0;

                    background: opacity(#f07167, 0.125);

                    @include media-breakpoint-down(md) {
                        height: 0.25rem;
                    };
                }
            } // .head
        } // &-header

        .item {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: flex-start;

            background: #f8edeb;
            border-radius: 0.5rem;
            box-shadow: 0 1rem 2rem opacity(#f8edeb, 0.5);

            padding: 2.5rem 1.5rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            };

            .img {
                display: block;

                height: 7rem;
                width: auto;

                user-select: none;
                pointer-events: none;

                margin-bottom: 1rem;

                position: relative;

                padding: 0.5rem;
                background: $white;
                border-radius: 50rem;

                @include media-breakpoint-down(md) {
                    height: 5rem;
                };

                .icon {
                    display: block;

                    height: 100%;
                    width: auto;
                } // .icon
            } // .img

            .head {
                display: block;

                color: #6d597a;
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1.25rem;

                margin: 0;
                padding: 0;

                margin-bottom: 0.5rem;
            } // .head

            .sub {
                display: block;

                color: opacity(#6d597a, 0.75);
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.25rem;

                margin: 0;
                padding: 0;
            } // .sub
        } // .item

        .timeline {
            list-style-type: none;
            position: relative;

            &:before {
                content: '';
                background: #f8edeb;
                display: inline-block;
                position: absolute;
                left: 29px;
                width: 2px;
                height: 100%;
                z-index: 400;

                @include media-breakpoint-down(md) {
                    left: 0.5rem;
                };
            } // &:before

            &-item {
                display: flex;
                flex-direction: column;
                align-items: baseline;

                background: $white;
                border-radius: 0.5rem;

                margin-right: 1rem;
                margin-bottom: 4rem;

                padding-left: 1.5rem;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                };

                &:before {
                    content: ' ';
                    background: white;
                    display: inline-block;
                    position: absolute;
                    border-radius: 50%;
                    border: 3px solid #6d6875;
                    left: 20px;
                    width: 20px;
                    height: 20px;
                    z-index: 400;

                    @include media-breakpoint-down(md) {
                        left: 0rem;
                    };
                }

                .head {
                    display: block;

                    color: $black;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 1.25rem;

                    margin: 0;
                    padding: 0;

                    margin-bottom: 2rem;
                } // .head
            } // &-item

            &-list {
                list-style: none;

                padding: 0;
                margin: 0;

                margin-bottom: 1rem;

                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    padding: 0;
                    margin: 0;

                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    color: $black;
                    font-size: 1.125rem;
                    font-weight: 500;
                    line-height: 1.25rem;

                    .check {
                        display: block;

                        height: 1rem;
                        width: auto;

                        user-select: none;
                        pointer-events: none;

                        margin-right: 0.5rem;
                    } // .check
                } // li
            } // &-list

            .gift {
                display: flex;
                flex-direction: column;
                align-items: baseline;

                &-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    color: $white;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.25rem;

                    margin: 0;
                    padding: 0;

                    background: #6d597a;
                    background: linear-gradient(-45deg, #6d597a, #f07167);

                    padding: 0.25rem 0.75rem;
                    border-radius: 50rem;

                    margin-bottom: 0.375rem;

                    @include media-breakpoint-down(md) {
                        border-radius: 0.25rem;
                    };

                    &:last-child {
                        margin-bottom: 0;
                    }
                } // &-item

                &-icon {
                    display: block;

                    height: 1rem;
                    width: auto;

                    user-select: none;
                    pointer-events: none;

                    margin-right: 0.375rem;
                    opacity: 0.5;
                } // &-icon
            } // .gift
        } // .timeline
    } // .todo


// #6d597a, #f07167
    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 3rem 2rem;
        border-radius: 0.5rem;
        background: linear-gradient(135deg, opacity(#6d597a, 0.125), opacity(#f07167, 0.25));

        .info {
            display: flex;
            flex-direction: column;
            text-align: center;

            width: 100%;

            .price {
                display: block;

                color: #6d597a;
                font-size: 2rem;
                font-weight: 500;
                line-height: 2.5rem;

                margin: 0;
                padding: 0;

                margin-bottom: 1.25rem;

                strong {
                    color: $white;
                    background: #f07167;

                    border-radius: 0.25rem;

                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                } // strong
            } // .price

            .text {
                display: inline-block;
                color: #6d597a;
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1.375rem;

                background: opacity(#6d597a, 0.125);
                border-radius: 0.25rem;

                padding: 1rem;

                margin-bottom: 2rem;
            } // .text
        } // .info

        .items {
            display: flex;
            flex-direction: row;
            align-items: center;

            width: 100%;

            margin-bottom: 2rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            };

            .training-form__item {
                display: flex;
                flex-direction: column;
                align-items: baseline;

                margin-left: 0.5rem;
                margin-right: 0.5rem;

                flex: 1;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin-right: 0;

                    margin-bottom: 1rem;

                    width: 100%;
                };

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                label {
                    display: block;

                    color: #6d597a;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1;

                    margin: 0;
                    padding: 0;

                    margin-bottom: 0.5rem;
                } // label

                input {
                    display: block;

                    position: relative;
                    width: 100%;

                    color: $black;
                    font-size: 1.25rem;
                    font-weight: 500;
                    line-height: 1;

                    background: $white;
                    border: 1px solid opacity(#6d597a, 0.25);
                    border-radius: 0.25rem;

                    margin: 0;
                    padding: 0;

                    padding: 1rem 0.75rem;

                    transition: $transition-base;

                    &:hover {
                        border-color: #6d597a;
                    }

                    &:focus,
                    &:active {
                        outline: none;
                        border-color: #6d597a;
                        box-shadow: 0 0 0 3px opacity(#6d597a, 0.125);
                    }
                }
            }
        } // .items

        .btn {
            color: $white;
            background: #6d597a;

            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.125rem;

            margin: 0;
            padding: 1rem 1.25rem;
            border-radius: 0.5rem;

            transition: $transition-base;

            margin-bottom: 2rem;

            @include media-breakpoint-down(md) {
                width: 100%;
            };

            &:hover {
                cursor: pointer;
                background: opacity(#6d597a, 0.75);
                text-decoration: none;
                transform: translateY(-3px);
                box-shadow: 0 1rem 1.5rem -0.75rem opacity(darken(#6d597a, 10%), 0.6);
            }
        } // .btn

        .help {
            color: opacity(#6d597a, 0.75);
            margin: 0;
            padding: 0;

            a {
                color: #f07167;
            }
        }
    } // &-form
} // .training