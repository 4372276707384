$monthly: #26EDA5;

.monthly {
    &-hero {
        padding-top: 5rem;
        padding-bottom: 5rem;

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding-top: 2rem;
            padding-bottom: 2rem;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                padding-top: 5rem;
                padding-bottom: 5rem;
            }
        }

        &__img {
            @include no-select;
            height: 30rem;
            width: auto;
            margin-bottom: 1rem;
        }

        &__title {
            color: #000;
            font-size: 4rem;
            font-weight: $font-weight-bold;
            text-align: center;
            max-width: 60%;

            @include media-breakpoint-down(md) {
                font-size: $font-size-lg * 1.25;
                max-width: 100%;
            }
        }

        &__description {
            color: mix(darken($monthly, 40%), #fff);
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.75rem;
            margin-bottom: 0;
            text-align: center;

            max-width: 70%;

            @include media-breakpoint-down(md) {
                font-size: 1rem;
                line-height: 1.25rem;
                max-width: 100%;
            }
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        border: 1px solid rgba(mix(darken($monthly, 40%), #fff), 0.25);
        border-radius: 1rem;
        padding: 2rem;

        @include media-breakpoint-down(md) {
            padding: 2rem 1.5rem;
        }

        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 0.5rem;
            }

            &__img {
                height: 2.5rem;
                width: auto;
                @include no-select;
                margin-right: 1rem;

                @include media-breakpoint-down(md) {
                    height: 2rem;
                    margin-right: 0.75rem;
                }
            }

            &__head {
                color: darken($monthly, 40%);
                font-size: 2rem;
                margin: 0;
                line-height: 2.25rem;
                padding: 0;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
            }
        }

        &__lead {
            color: mix(darken($monthly, 40%), #fff);
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin-bottom: 0;

            a {
                color: mix(darken($monthly, 40%), #fff);
                text-decoration: none;
                transition: all 0.15s linear;

                &:hover {
                    color: mix(darken($monthly, 40%), #fff, 70%);
                }
            }
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: darken($monthly, 20%);
        border-radius: 1rem;
        padding: 3rem 2rem;

        margin-bottom: 2rem;

        @include media-breakpoint-down(md) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &__head {
            color: $white;
            font-size: 3rem;
            font-weight: 500;
            margin-bottom: 0.25rem;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: 1.75rem;
            }
        }

        &__sub {
            color: lighten($monthly, 10%);
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 2rem;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
        }

        &-help {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 70%;

            margin-top: 4rem;

            color: #fff;

            padding: 2rem;
            border-radius: 1rem;

            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &-gift {
            display: flex;
            align-items: center;

            position: relative;

            margin-top: 4rem;
            padding: 2rem;

            background: #fff;
            border-radius: 1rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            &__text {
                display: flex;
                flex-direction: column;
                align-items: baseline;

                width: calc(60% - 1rem);
                margin-right: 1rem;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }

            &__title {
                color: #000;
                font-size: 2rem;
                margin: 0;
                line-height: 2.25rem;
                padding: 0;
                margin-bottom: 0.5rem;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
            }

            &__lead {
                color: mix($monthly, #000);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.25rem;
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-covers {
                display: flex;
                align-items: center;

                width: 40%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &__img {
                    display: block;
                    width: calc(50% - 0.5rem);
                    height: auto;

                    position: relative;
                    z-index: 1;

                    @include no-select;

                    border-radius: 1rem;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.5);

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &__label {
                color: rgba($white, 0.25);
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1;
                margin: 0 0 1rem;
            }

            &__input {
                width: 100%;
                color: $black;
                font-size: $font-size-lg * 3;
                font-weight: $font-weight-base;
                line-height: 1;

                background: $white;
                border: none;
                box-shadow: 0 0 0 transparent;
                border-radius: 0.5rem;

                margin: 0;
                padding: 1rem 0.75rem;
                text-align: center;

                transition: $transition-base;

                @include media-breakpoint-down(md) {
                    font-size: $font-size-lg * 1.5;
                }

                &:focus,
                &:active {
                    outline: none;
                    box-shadow: 0 0 1rem $sigil;
                }
            }

            &-row {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                .monthly-form-item {
                    margin-right: 0.75rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &__btn {
            display: block;
            position: relative;
            overflow: hidden;

            color: $black;
            background: $monthly;
            border: none;

            box-shadow: 0 1rem 1rem -0.5rem opacity($black, 0.3);

            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin: 0;
            padding: 1rem 1.5rem;
            border-radius: 25rem;

            margin-bottom: 1rem;

            margin-top: 2rem;
            transition: $transition-base;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                background: linear-gradient(
                        -92deg,
                        opacity($white, 0.7),
                        opacity($white, 0.7)
                );

                width: 3rem;
                height: 100%;

                transform: skewX(-42deg) translateX(-10%);
                z-index: 1;
                will-change: transform;

                opacity: 0;

                transition: all 0.3s ease;
            } // &:before

            &:hover {
                color: $black;
                text-decoration: none;

                box-shadow: 0 0.25rem 2rem rgba($sigil, 0.6);

                &:before {
                    transform: skewX(-42deg) translateX(510%);
                    opacity: 1;
                } // &:before
            }

            &:focus,
            &:active {
                outline: none;
            }
        }

        &__help {
            display: block;

            color: lighten($teal, 20%);
            font-size: $font-size-sm;
            font-weight: $font-weight-base;
            line-height: 1.2;
            margin: 0 auto;
            margin-top: 1rem;
            text-align: center;
            padding: 0;

            a {
                color: lighten($teal, 20%);
                text-decoration: none;

                border-bottom: 1px solid opacity(lighten($teal, 20%), 0.23);
                transition: $transition-base;

                &:hover {
                    text-decoration: none;
                    color: $white;
                    border-bottom-color: opacity($white, 0.23);
                }
            }
        } // &__help
    } // &-form


}