body.reviews {
    .header {
        background: lighten($blue, 45%);

        &__insta {
            border-left-color: lighten($blue, 43%);

            .icon {
                color: $blue;
            } // svg
        } // &__insta

        &-clients {
            border-right-color: lighten($blue, 43%);

            &__head,
            &__sub {
                color: lighten($blue, 10%);
            } // &__head
        } // &-clients

        &-menu {
            border-top-color: lighten($blue, 43%);
            transition: $transition-base;
        } // &-menu

        &__btn {
            border-color: $blue;
            color: $blue;

            &:before {
                background: linear-gradient(
                    -92deg,
                    opacity($white, 0.7),
                    opacity($white, 0.7)
                );
            }

            &:hover {
                color: $white;
                border-color: $purple;
                background: $purple;
            }
        } // &__btn

        @include media-breakpoint-down(md) {
            &.open {
                background: $white;
                box-shadow: 0 1rem 1.5rem -0.75rem opacity(darken($pink, 10%), 0.6);

                .header {
                    &-logo {
                        .light {
                            fill: $gray-200;
                        } // .light

                    } // &-logo

                    &__insta {
                        svg {
                            fill: $gray-300;
                        } // svg

                        &:hover {
                            svg {
                                fill: $pink;
                            }
                        }
                    } // &__insta

                    &-menu {
                        border-top-color: $gray-200;
                    } // &-menu
                }
            } // &.open
        }

    } // .header
} // body.home

.reviews {

    &__img {
        display: block;

        height: 3rem;
        width: auto;

        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5rem;

        @include no-select;
    } // &__img

    &__subhead {
        display: block;

        color: $blue;

        font-size: $font-size-lg;
        font-weight: $font-weight-base;
        line-height: 1;
        padding: 0;
        margin: 0;
        @include media-breakpoint-down(md) {
            font-size: $font-size-base * 1.125;
            margin-bottom: 0.125rem;
        }
    } // &__subhead

    &__head {
        display: block;
        color: $black;

        font-size: $font-size-lg * 2;
        font-weight: $font-weight-bold;
        line-height: 1;
        padding: 0;
        margin: 0;

        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
            font-size: $font-size-lg * 1.25;
            br {
                display: none;
            }
        }
    } // &__head

    &-hero {
        background: linear-gradient(lighten($blue, 45%), $white);

        padding-top: 6rem;
        padding-bottom: 3rem;
    } // &-hero

    &-instagram {
        background: $white;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        border: 1px solid $gray-200;
        border-radius: 0.5rem;

        padding: 1.5rem 1rem;

        margin-top: 1rem;

        &__img {
            @include no-select;
            display: block;
            max-width: 40%;
            height: auto;
            border-radius: 50rem;

            margin-bottom: 1rem;
        } // &__img

        &__head {
            display: block;

            color: $black;
            font-size: $font-size-lg * 1.25;
            font-weight: $font-weight-bold;
            line-height: 1;

            margin: 0;
            padding: 0;

            margin-bottom: 0.25rem;

            text-align: center;
        } // &__head

        &__subhead {
            display: block;
            color: $gray-600;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            font-style: italic;

            margin: 0;
            padding: 0;

            margin-bottom: 1rem;
        } // &__subhead

        &__sub {
            display: block;

            color: $black;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1.6;

            margin: 0;
            padding: 0;

            margin-bottom: 1rem;

            width: 100%;
            max-height: 13rem;

            @include media-breakpoint-down(md) {
                text-align: justify;
            }
        } // &__sub
        &__link {
            display: block;
            text-decoration: none;

            color: $black;
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            line-height: 1;

            margin: 0;
            padding: 0;

            position: relative;
            overflow: hidden;

            background: $white;
            padding: 0.5rem 1.25rem;
            border: 2px solid lighten($blue, 10%);
            border-radius: 25rem;

            box-shadow: 0 0.5rem 2rem -0.25rem opacity(darken($blue, 10%), 0.3);

            transition: $transition-base;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                background: linear-gradient(
                    -92deg,
                    opacity($blue, 0.7),
                    opacity($blue, 0.7)
                );

                width: 3rem;
                height: 100%;

                transform: skewX(-42deg) translateX(-10%);
                z-index: 1;
                will-change: transform;

                opacity: 0;

                transition: all 0.3s ease;
            } // &:before

            &:hover {
                color: $white;
                background: $blue;
                border-color: $blue;

                text-decoration: none;
                transform: translateY(-4px);

                box-shadow: 0 1.5rem 1.5rem -0.75rem opacity(darken($blue, 10%), 0.7);

                &:before {
                    transform: skewX(-42deg) translateX(460%);
                    opacity: 1;
                } // &:before
            }
        }
    } // &-instagram

    &-whatsapp {
        background: $white;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        border: 1px solid $gray-200;
        border-radius: 0.5rem;

        padding: 1.5rem 1rem;

        margin-top: 1rem;

        &__img {
            @include no-select;
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 0.5rem;
        } // &__img
    } // &-whatsapp
} // .reviews