$shop-primary: #17d1b8;
$shop-dark: #09302b;
$shop-danger: #f5576c;

.shop {
    &-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;

        &__title {
            display: inline-flex;
            color: $shop-dark;
            font-size: 2.5rem;
            font-weight: 600;
            line-height: 1.375rem;
            margin-bottom: 1rem;
            position: relative;
            z-index: 1;

            @include media-breakpoint-down(lg) {
                font-size: 1.5rem;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 0.25rem;
                background-color: $shop-primary;
                bottom: -0.75rem;
                left: 0;
            }
        }
    }

    &-hero {
        &__content {
            display: flex;
            justify-content: center;
            padding-top: 7rem;
            padding-bottom: 7rem;
            margin-bottom: 5rem;
            margin-top: 1rem;

            position: relative;
            overflow: hidden;

            background-color: rgba($shop-primary, 0.125);
            border-radius: 1rem;
        }

        &__logo {
            height: 7rem;
            margin: 0 auto;
            user-select: none;
            pointer-events: none;

            @include media-breakpoint-down(md) {
                width: 100%;
                height: auto;
            }
        }

        &__bg {
            opacity: 0.025;
            width: 100%;
            position: absolute;
            z-index: -1;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-products {
        margin-bottom: 5rem;
    }

    &-product {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        background-color: white;
        border-radius: 0.75rem;
        box-shadow: inset 0 0 0 1px rgba($shop-dark, 0.125);

        padding: 2rem;
        position: relative;

        margin-bottom: 1rem;

        &__img {
            height: auto;
            width: 60%;
            user-select: none;
            pointer-events: none;
            margin: 0 auto 2rem;
        }

        &__title {
            color: $shop-dark;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1rem;
            margin-bottom: 1rem;
        }

        &-description {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            margin-bottom: 1rem;

            &__item {
                color: mix($shop-dark, white);
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.25rem;
                margin-bottom: 0.5rem;

                strong {
                    color: $shop-primary;
                    font-weight: 500;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &--add {
                    width: 100%;
                    background-color: rgba($shop-primary, 0.125);
                    border-radius: 0.5rem;
                    padding: 1rem;
                }
            }
        }

        &-content {
            width: 100%;
            padding-top: 1rem;
            margin-top: 0.5rem;
            border-top: 1px solid rgba($shop-dark, 0.125);

            color: mix($shop-dark, white);
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;

            &__badge {
                color: darken(#FFD700, 40%);
                background: #FFD700;
                border-radius: 50rem;
                padding: 0 0.5rem;

                &.silver {
                    color: darken(#C0C0C0, 40%);
                    background: #C0C0C0;
                }
            }
        }
    }

    &-list {
        background-color: rgba($shop-primary, 0.125);
        padding-top: 5rem;
        padding-bottom: 1rem;
        border-radius: 1rem;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        background-color: white;
        border-radius: 0.75rem;

        padding: 2rem;
        position: relative;

        margin-bottom: 1rem;

        &--unavailable {
            pointer-events: none;
            user-select: none;
            overflow: hidden;

            &:before {
                content: 'Товар недоступен';
                color: #fff;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1rem;
                background-color: rgba(0, 0, 0, 0.5);
                padding: 0.75rem 1.25rem;
                border-radius: 50rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                z-index: 2;
            }

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(3px);
            }
        }

        &__badge {
            color: white;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1;
            background-color: $shop-danger;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            user-select: none;
            pointer-events: none;

            position: absolute;
            top: 1rem;
            right: 1rem;
        }

        &__img {
            height: auto;
            width: 100%;
            user-select: none;
            pointer-events: none;
            margin-bottom: 1rem;
            border-radius: 0.5rem;
        }

        &__title {
            color: $shop-dark;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1rem;
            margin-bottom: 2rem;
        }

        &__description {
            color: mix($shop-dark, white);
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &-prices {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;
            width: 100%;

            padding-top: 0.75rem;
            margin-bottom: 1rem;
            border-top: 1px solid rgba($shop-dark, 0.125);

            &-col {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                &__title {
                    color: mix($shop-dark, white);
                    font-size: 1.25rem;
                    font-weight: 400;
                    line-height: 1rem;
                }

                &__main {
                    color: $shop-dark;
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 1rem;
                    letter-spacing: 1px;
                    margin-bottom: 0.5rem;
                }

                &__item {
                    color: rgba($shop-dark, 0.25);
                    font-size: 0.825rem;
                    font-weight: 400;
                    line-height: 1rem;
                    margin-bottom: 0.25rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;

            background-color: $shop-primary;
            border: none;
            border-radius: 0.75rem;
            box-shadow: rgba($shop-primary, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            transition: all 0.15s linear;

            position: relative;
            user-select: none;

            padding: 1rem;

            &:focus,
            &:active {
                outline: none;
            }

            &:hover {
                background-color: lighten($shop-primary, 10%);
                box-shadow: rgba($shop-primary, 0.25) 0 30px 40px -5px, rgba(0, 0, 0, 0.3) 0px 10px 15px -8px;
            }

            &__icon {
                color: mix($shop-primary, $shop-dark, 30%);
                font-size: 1.5rem;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &__text {
                color: $shop-dark;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1rem;
            }
        }
    }
}