.tg {
    overflow: hidden;
    max-width: 100%;

    &-hero {
        margin-top: 4rem;
        margin-bottom: 2rem;

        &__img {
            display: block;
            height: 8rem;
            width: auto;
            user-select: none;
            pointer-events: none;

            margin: 0 auto;

            @include media-breakpoint-down(md) {
                height: 4rem;
            }
        } // &__img
    } // &-hero

    &-home {
        position: relative;
        z-index: 1;

        .container {
            position: relative;
            z-index: 1;
        }

        &__img {
            display: block;
            margin: 0 auto;
            max-width: 70%;

            user-select: none;
            pointer-events: none;

            @include media-breakpoint-down(md) {
                max-width: 130%;
                margin-left: -20%;
            }
        } // &__img

        &__bg1 {
            display: block;
            margin: 0 auto;
            max-width: 70%;

            user-select: none;
            pointer-events: none;

            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;

            @include media-breakpoint-down(md) {
                max-width: 170%;
                margin-left: 0;
            }

            &.bg2 {
                @include media-breakpoint-down(md) {
                    max-width: 170%;
                    margin-left: -60%;
                }
            }

            &.bg3 {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    max-width: 170%;
                    margin-left: -100%;
                }
            }
        } // &__bg1

        &:after {
            content: '';
            background: #f0c3b8;
            background: linear-gradient(78deg, #f0c3b8, #f5b1b1);

            position: absolute;
            z-index: -1;

            left: 0;
            bottom: 0;

            width: 100%;
            height: 80%;
        } // &:after
    } // &-home

    &-prem {
        background: #fff;
        position: relative;
        z-index: 3;

        padding-top: 8rem;
        margin-bottom: 2rem;

        @include media-breakpoint-down(md) {
            padding-top: 4rem;
            margin-left: -1rem;
            margin-right: -1rem;

            width: calc(100% + 2rem);
        }

        &-list {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            width: 100%;

            background: rgba(240, 195, 184, 0.5);
            background: linear-gradient(78deg, rgba(240, 195, 184, 0.5), rgba(245, 177, 177, 0.5));
            border-radius: 1rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            padding: 3rem 2rem;

            @include media-breakpoint-down(md) {
                padding: 2rem 1rem;
                border-radius: 0;
            }

            &__head {
                display: block;

                color: #362b2b;
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 1rem;

                line-height: 1.825rem;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                    line-height: 1.25rem;
                    margin-bottom: 2rem;
                }
            }
        } // &-list

        &-item {
            display: flex;
            align-items: flex-start;

            width: 100%;

            margin-bottom: 1rem;

            background: #fff;
            border-radius: 0.5rem;

            padding: 1rem;
            padding-left: 3rem;

            position: relative;
            z-index: 1;
            box-shadow: 0 0.25rem 0.5rem rgba(48, 37, 37, 0.125);

            &__icon {
                display: block;
                font-size: 1.25rem;
                color: #ff6f20;

                position: absolute;
                z-index: 1;

                left: 1rem;
                top: 1.125rem;

                @include media-breakpoint-down(md) {
                    top: 50%;
                    transform: translateY(-50%);
                }
            } // &__icon

            &__text {
                display: block;
                margin: 0;
                padding: 0;
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1.5rem;

                color: #362b2b;
            } // &__text
        } // &-item

        &-footer {
            margin-top: 1rem;
            opacity: 0.5;
        } // &-footer

        &-price {
            background: rgba(240, 195, 184, 0.5);
            background: linear-gradient(78deg, rgba(240, 195, 184, 0.5), rgba(245, 177, 177, 0.5));
            border-radius: 1rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            padding: 3rem 2rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(md) {
                margin-left: -1rem;
                margin-right: -1rem;

                width: calc(100% + 2rem);
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                width: 100%;
                background: #fff;
                border-radius: 1rem;

                padding: 2rem 1rem;
            } // &-content

            &__prehead {
                display: block;

                color: #ff9f2e;
                font-size: 0.825rem;
                line-height: 1;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: 1px;

                margin: 0;
                padding: 0;
            } // &__prehead

            &__head {
                display: block;

                color: #362b2b;
                font-size: 2rem;
                line-height: 1;
                font-weight: 600;

                margin: 0;
                padding: 0;

                margin-bottom: 2rem;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
            } // &__head

            &__price {
                display: block;

                color: #362b2b;
                font-size: 5rem;
                line-height: 1;
                font-weight: 600;

                margin: 0;
                padding: 0;

                margin-bottom: 0.5rem;

                background: linear-gradient(76deg, #ff9f2e, #ff4817);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include media-breakpoint-down(md) {
                    font-size: 3rem;
                }
            } // &__price

            &-help {
                display: flex;
                align-items: center;

                margin: 2rem auto 0 auto;

                border: 1px solid rgba(255, 72, 23, 0.375);
                border-radius: 0.5rem;
                padding: 1rem;

                width: 80%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &__icon {
                    color: rgba(255, 72, 23, 1);
                    font-size: 2rem;
                    opacity: 0.5;
                    margin-right: 0.75rem;
                } // &__icon

                &__text {
                    color: rgba(255, 72, 23, 1);
                    font-size: 1rem;
                    line-height: 1.125rem;
                    font-weight: 400;
                } // &__text
            } // &-help

            &__addprice {
                display: block;

                color: #362b2b;
                font-size: 1rem;
                line-height: 1;
                font-weight: 400;

                margin: 0;
                padding: 0;

                opacity: 0.75;

                margin-bottom: 2rem;
            } // &__addprice

            &-btn {
                display: flex;
                align-items: center;

                background: transparent;
                border: 1px solid rgba(255, 72, 23, 0.25);
                background: rgba(255, 72, 23, 0.05);
                border-radius: 0.5rem;

                padding: 1rem 1.25rem;

                transition: all 0.15s linear;

                &__text {
                    color: #362b2b;
                    font-size: 1.25rem;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;

                    transition: all 0.15s linear;
                } // &__text

                &__icon {
                    color: rgba(255, 72, 23, 1);
                    font-size: 1.5rem;

                    margin-left: 0.25rem;

                    transition: all 0.15s linear;
                } // &__icon

                &:hover {
                    border: 1px solid rgba(255, 72, 23, 1);
                    background: rgba(255, 72, 23, 1);
                    cursor: pointer;

                    .tg-prem-price-btn {
                        &__text {
                            color: #fff;
                        }

                        &__icon {
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }
                } // &:hover
            } // &-btn
        } // &-price

        &-help {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-align: center;

            width: 100%;

            margin-top: 4rem;
            margin-bottom: 0;

            &__lead {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                color: rgba(0, 0, 0, 0.5);
                font-size: 1.125rem;
                font-weight: 400;
                text-align: center;

                a {
                    color: rgba(255, 72, 23, 1);
                    word-break: keep-all;
                    margin-left: 0.5rem;
                } // a
            } // &__lead
        } // &-help
    } // &-prem
} // .tg