.kids {
    &-card {
        display: block;

        background: $white;

        padding: 0;
        margin: 0;

        margin-bottom: 1rem;

        &-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            border: 1px solid $gray-200;
            border-radius: 0.5rem;
            padding: 1.25rem 1rem;

            &:hover {
                cursor: pointer;
            }

            &.collapsed {
                .kids-card__img {
                    transform: rotate(0);
                }
            }
        } // &-title

        &__head {
            display: block;
            color: $black;

            font-size: $font-size-lg;
            font-weight: $font-weight-base;
            line-height: 1;
        } // &__head
        &__img {
            display: block;

            fill: $gray-400;

            width: auto;
            height: 0.75rem;

            transform: rotate(-180deg);

            transition: $transition-base;
        } // &__img

        &__lead {
            display: block;

            color: $black;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1.5;
        } // &__lead

        &-content {
            padding: 1.25rem 1rem;
        }
    } // &-card
} // .kids
