$sigil: #26EDA5;

.sigil {
    &-hero {
        padding-top: 5rem;
        padding-bottom: 5rem;

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            padding-top: 10rem;
            padding-bottom: 10rem;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                padding-top: 5rem;
                padding-bottom: 5rem;
            }

            &:after {
                content: '';
                display: block;

                position: absolute;
                z-index: -3;
                border-radius: 1rem;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background: darken($sigil, 45%);
            }

            &:before {
                content: '';
                display: block;

                position: absolute;
                z-index: -2;
                border-radius: 1rem;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background: linear-gradient(transparent, rgba($sigil, 0.25));
            }
        }

        &__bg {
            display: block;
            position: absolute;
            z-index: -2;
            width: 120%;
            height: auto;
            opacity: 0.05;
            transform: rotate(-12deg);
            user-select: none;
            pointer-events: none;

            @include media-breakpoint-down(md) {
                width: 300%;
            }
        }

        &-sigil {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            height: 100%;
            width: auto;
            position: absolute;
            z-index: -1;
            @include no-select;
            opacity: 0;

            transition: all 0.15s linear;

            &.animating {
                animation-duration: 3s;
                animation-name: fadein;

                @keyframes fadein {
                    0% { opacity: 0; }
                    50% { opacity: 0.125; }
                    100% { opacity: 0; }
                }
            }

            &__inner {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: auto;
                fill: $sigil;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__head {
                color: #fff;
                font-size: 10rem;
                font-weight: $font-weight-bold;
                line-height: 1;

                margin: 0;
                padding: 0;

                margin-bottom: 1rem;

                @include media-breakpoint-down(md) {
                    font-size: $font-size-lg * 3;
                }
            } // &__head

            &__sub {
                display: block;

                color: $sigil;
                font-size: $font-size-lg * 1.5;
                font-weight: $font-weight-base;
                line-height: 1;

                margin: 0;
                padding: 0;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                }
            } // &__sub
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        border: 1px solid rgba(mix(darken($sigil, 40%), #fff), 0.25);
        border-radius: 1rem;
        padding: 2rem;
        height: 100%;

        @include media-breakpoint-down(md) {
            padding: 2rem 1.5rem;
        }

        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 0.5rem;
            }

            &__img {
                height: 2.5rem;
                width: auto;
                @include no-select;
                margin-right: 1rem;

                @include media-breakpoint-down(md) {
                    height: 2rem;
                    margin-right: 0.75rem;
                }
            }

            &__head {
                color: darken($sigil, 40%);
                font-size: 2rem;
                margin: 0;
                line-height: 2.25rem;
                padding: 0;

                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
            }
        }

        &__lead {
            color: mix(darken($sigil, 40%), #fff);
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin-bottom: 0;

            a {
                color: mix(darken($sigil, 40%), #fff);
                text-decoration: none;
                transition: all 0.15s linear;

                &:hover {
                    color: mix(darken($sigil, 40%), #fff, 70%);
                }
            }
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: darken($sigil, 40%);
        border-radius: 1rem;
        padding: 3rem 2rem;

        margin-bottom: 2rem;

        @include media-breakpoint-down(md) {
            padding-left: 1rem;
            padding-right: 1rem;
        }


        &-gift {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            border: 1px dashed rgba($white, 0.25);
            border-radius: 0.5rem;
            padding: 2rem;
            font-size: 1.25rem;
            font-weight: 500;
            margin-top: 2rem;
            margin-bottom: 1rem;

            transition: all 0.15s linear;
            cursor: pointer;

            position: relative;

            cursor:pointer;

            &-checkbox {
                position: absolute;
                z-index: -1;
                opacity: 0;
                cursor:pointer;

                &:checked {
                    + label {
                        &:before {
                            border-color: $sigil;
                            background-color: $sigil;
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                        }
                    }
                }

                + label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    user-select: none;
                    margin-bottom: 0;
                    cursor:pointer;

                    small {
                        font-size: 1rem;
                        font-weight: 400;
                        opacity: 0.5;
                    }

                    &:before {
                        cursor:pointer;
                        content: "";
                        margin-bottom: 1rem;
                        display: inline-block;
                        width: 3rem;
                        height: 3rem;
                        flex-shrink: 0;
                        flex-grow: 0;
                        border: 1px solid rgba($white, 0.25);
                        border-radius: 0.375rem;
                        margin-right: 0.5rem;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 50% 50%;
                    }
                }
            }

            &:hover {
                color: $sigil;
            }

            &-form {
                display: flex;
                flex-direction: column;
                align-items: center;

                box-shadow: inset 0 0 0 1.5px $warning;
                border-radius: 1rem;

                width: 70%;

                position: relative;
                opacity: 0;
                z-index: -1;
                height: 0;
                transition: all 0.15s linear;
                padding: 0;
                overflow: hidden;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &-badge {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $warning;
                    padding: 1.5rem 1rem;

                    margin-bottom: 2rem;

                    width: 100%;

                    &__icon {
                        color: $danger;
                        font-size: 1.5rem;
                        margin-right: 0.375rem;
                    }

                    &__text {
                        color: $dark;
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }

                &-lead {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    &__add {
                        display: block;
                        color: $white;
                        text-align: center;
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 1.5rem;
                        border-top: 1px solid $warning;
                        border-bottom: 1px solid $warning;
                        margin: 2rem 0;
                        padding: 2rem 1rem;

                        strong {
                            border-bottom: 2px solid $sigil;
                        }

                        &-checkbox {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            cursor: pointer;
                            margin-top: 1rem;
                            position: relative;

                            &-checkbox {
                                position: absolute;
                                z-index: -1;
                                opacity: 0;
                                cursor: pointer;

                                &:checked {
                                    + label {
                                        &:before {
                                            border-color: $sigil;
                                            background-color: $sigil;
                                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                                        }
                                    }
                                }

                                + label {
                                    display: inline-flex;
                                    align-items: center;
                                    user-select: none;
                                    margin-bottom: 0;
                                    cursor: pointer;

                                    &:before {
                                        cursor: pointer;
                                        content: "";
                                        display: inline-block;
                                        width: 1.75rem;
                                        height: 1.75rem;
                                        flex-shrink: 0;
                                        flex-grow: 0;
                                        border: 1px solid rgba($white, 0.25);
                                        border-radius: 0.375rem;
                                        margin-right: 0.5rem;
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                        background-size: 50% 50%;
                                    }
                                }
                            }
                        }
                    }

                    &__lead {
                        color: $white;
                        font-size: 1.25rem;
                        font-weight: 500;
                        line-height: 1.5rem;
                        text-align: center;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        margin-bottom: 2rem;
                    }

                    &-list {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        padding-left: 1rem;
                        padding-right: 1rem;

                        &__item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;

                            background: rgba($white, 0.05);
                            box-shadow: inset 0 0 0 1px $sigil;
                            border-radius: 0.5rem;
                            margin-bottom: 1rem;
                            padding: 1.5rem 2rem;
                            color: $white;
                            font-size: 1.25rem;
                            font-weight: 500;
                            line-height: 1.5rem;

                            small {
                                font-size: 0.75rem;
                                line-height: 1rem;
                                font-weight: 400;
                                color: rgba($white, 0.5);
                                margin-top: 0.5rem;
                            }

                            &-list {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-around;
                                flex-wrap: wrap;

                                margin-top: 2rem;

                                &__item {
                                    flex: 0 0 calc(25% - 1rem);
                                    max-width: calc(25% - 1rem);
                                    margin-left: 0.5rem;
                                    margin-right: 0.5rem;
                                    position: relative;

                                    @include media-breakpoint-down(md) {
                                        flex: 0 0 calc(50% - 1rem);
                                        max-width: calc(50% - 1rem);
                                    }

                                    input {
                                        position: absolute;
                                        z-index: -1;
                                        opacity: 0;
                                        cursor: pointer;

                                        &:checked {
                                            + label {
                                                box-shadow: inset 0 0 0 2px $sigil;

                                                &:after {
                                                    opacity: 1;
                                                }
                                            }
                                        }

                                        + label {
                                            cursor: pointer;
                                            display: inline-flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            user-select: none;
                                            border-radius: 0.5rem;
                                            overflow: hidden;
                                            position: relative;

                                            box-shadow: inset 0 0 0 0 $sigil;

                                            &:after {
                                                content: 'Выбрана';
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                justify-content: center;
                                                position: absolute;
                                                z-index: -2;
                                                top: auto; left: auto; right: auto; bottom: auto;
                                                font-size: 1.25rem;
                                                color: $black;
                                                background: rgba($sigil, 0.75);
                                                width: 100%;
                                                height: 100%;
                                                opacity: 0;
                                            }
                                        }
                                    }

                                    img {
                                        @include no-select;
                                        max-width: 100%;
                                        position: relative;
                                        z-index: -2;
                                    }
                                }
                            }
                        }
                    }
                }

                &__inputs {
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    width: 100%;

                    padding-left: 1rem;
                    padding-right: 1rem;

                    padding-bottom: 2rem;

                    .sigil-form-item {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }

                &.visible {
                    opacity: 1;
                    z-index: 1;
                    height: auto;
                }
            }
        }

        &__head {
            color: $white;
            font-size: 3rem;
            font-weight: 500;
            margin-bottom: 0.25rem;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: 1.75rem;
            }
        }

        &__sub {
            color: lighten($sigil, 10%);
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 2rem;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &__label {
                color: rgba($white, 0.25);
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1;
                margin: 0 0 1rem;
            }

            &__input {
                width: 100%;
                color: $black;
                font-size: $font-size-lg * 3;
                font-weight: $font-weight-base;
                line-height: 1;

                background: $white;
                border: none;
                box-shadow: 0 0 0 transparent;
                border-radius: 0.5rem;

                margin: 0;
                padding: 1rem 0.75rem;
                text-align: center;

                transition: $transition-base;

                @include media-breakpoint-down(md) {
                    font-size: $font-size-lg * 1.5;
                }

                &:focus,
                &:active {
                    outline: none;
                    box-shadow: 0 0 1rem $sigil;
                }
            }
        }

        &__btn {
            display: block;
            position: relative;
            overflow: hidden;

            color: $black;
            background: $sigil;
            border: none;

            box-shadow: 0 1rem 1rem -0.5rem opacity($black, 0.3);

            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin: 0;
            padding: 1rem 1.5rem;
            border-radius: 25rem;

            margin-bottom: 1rem;

            margin-top: 2rem;
            transition: $transition-base;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                background: linear-gradient(
                        -92deg,
                        opacity($white, 0.7),
                        opacity($white, 0.7)
                );

                width: 3rem;
                height: 100%;

                transform: skewX(-42deg) translateX(-10%);
                z-index: 1;
                will-change: transform;

                opacity: 0;

                transition: all 0.3s ease;
            } // &:before

            &:hover {
                color: $black;
                text-decoration: none;

                box-shadow: 0 0.25rem 2rem rgba($sigil, 0.6);

                &:before {
                    transform: skewX(-42deg) translateX(510%);
                    opacity: 1;
                } // &:before
            }

            &:focus,
            &:active {
                outline: none;
            }
        }

        &__help {
            display: block;

            color: lighten($teal, 20%);
            font-size: $font-size-sm;
            font-weight: $font-weight-base;
            line-height: 1.2;
            margin: 0 auto;
            margin-top: 1rem;
            text-align: center;
            padding: 0;

            a {
                color: lighten($teal, 20%);
                text-decoration: none;

                border-bottom: 1px solid opacity(lighten($teal, 20%), 0.23);
                transition: $transition-base;

                &:hover {
                    text-decoration: none;
                    color: $white;
                    border-bottom-color: opacity($white, 0.23);
                }
            }
        } // &__help
    } // &-form
} // .money


.sigil-result {
    &-hero {
        background: darken($sigil, 40%);
        padding-top: 4rem;

        &__back {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;

            &:hover {
                text-decoration: none;

                .sigil-result-hero__arr {
                    fill: $sigil;
                }
                .sigil-result-hero__link {
                    text-decoration: none;
                    color: $sigil;
                }
            }
        } // &__back

        &__arr {
            @include no-select;

            display: block;

            height: 1rem;
            width: auto;

            margin-right: 0.5rem;

            fill: $white;
            transition: $transition-base;
        } // &__arr

        &__link {
            display: block;

            text-decoration: none;

            color: $white;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1;

            margin: 0;
            padding: 0;
            transition: $transition-base;
        }

        &__logo {
            display: block;
            width: 10rem;
            height: auto;

            margin: 0 auto;

            @include media-breakpoint-down(md) {
                width: 8rem;
            }

            @include media-breakpoint-down(lg) {
                margin-left: auto;
                margin-right: 0;
            }
        } // &__img

        &__top {
            display: block;

            background: $white;

            width: 100%;

            color: $black;
            font-size: 2rem;
            font-weight: 500;
            line-height: 1;
            text-align: center;

            padding: 2rem 1rem;
            margin: 2rem 0 0;

            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        } // &__top
    } // &-hero

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem;
    }

    &-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include no-select;

        width: 50%;

        padding: 2rem;
        border-radius: 1rem;
        box-shadow: 0 0 0 1px rgba(mix(darken($sigil, 40%), #fff), 0.25);

        position: relative;
        z-index: 1;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        &__img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        margin-top: 2rem;
    }

    &__btn {
        display: flex;
        align-items: center;
        text-align: center;

        position: relative;
        overflow: hidden;

        color: $white;
        background: darken($sigil, 40%);
        box-shadow: 0 0 0 0 opacity($sigil, 0);

        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;

        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;

        transition: $transition-base;

        &:hover {
            color: $black;
            background: $sigil;
            text-decoration: none;
            box-shadow: 0 1rem 2rem -1rem opacity($sigil, 0.6);
        }

        &:focus,
        &:active {
            outline: none;
        }
    } // &__btn
}