body.home {
    .header {
        background: lighten($purple, 32%);

        &__insta {
            border-left-color: lighten($purple, 28%);
        }

        &-clients {
            border-right-color: lighten($purple, 28%);

            &__head,
            &__sub {
                color: $purple;
            }
        }

        &-menu {
            border-top-color: lighten($purple, 28%);
            transition: $transition-base;
        } // &-menu

        @include media-breakpoint-down(md) {
            &.open {
                background: $white;
                box-shadow: 0 1rem 1.5rem -0.75rem opacity(darken($purple, 10%), 0.6);

                .header {
                    &-logo {
                        .light {
                            fill: $gray-200;
                        } // .light

                    } // &-logo

                    &__insta {
                        svg {
                            fill: $gray-300;
                        } // svg

                        &:hover {
                            svg {
                                fill: $purple;
                            }
                        }
                    } // &__insta

                    &-menu {
                        border-top-color: $gray-200;
                    } // &-menu
                }
            } // &.open
        }

    } // .header
} // body.home

.home {
    &__img {
        display: block;

        height: 3rem;
        width: auto;

        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.5rem;

        @include no-select;
    } // &__img

    &__subhead {
        display: block;

        color: $purple;

        font-size: $font-size-lg;
        font-weight: $font-weight-base;
        line-height: 1;
        padding: 0;
        margin: 0;
        @include media-breakpoint-down(md) {
            font-size: $font-size-base * 1.125;
            margin-bottom: 0.125rem;
        }
    } // &__subhead

    &__head {
        display: block;
        color: $black;

        font-size: $font-size-lg * 2;
        font-weight: $font-weight-medium;
        line-height: 1;
        padding: 0;
        margin: 0;

        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
            font-size: $font-size-lg * 1.25;
            br {
                display: none;
            }
        }
    } // &__head

    &-hero {
        padding-top: 2rem;
        background: linear-gradient(lighten($purple, 32%), $white);

        @include media-breakpoint-down(md) {
            text-align: center;
        }
        &__img {
            @include no-select;

            max-width: 100%;
            height: auto;

            display: block;
        }
        &__head {
            display: block;

            color: $black;
            font-size: $font-size-lg * 2.25;
            font-weight: $font-weight-bold;
            margin: 0;
            padding: 0;
            line-height: 1;
            margin-bottom: 1rem;

            @include media-breakpoint-down(md) {

                font-size: $font-size-lg * 2;
            }
        } // &__head
        &__sub {
            display: block;

            color: $purple;
            font-size: $font-size-lg * 1.5;
            font-weight: $font-weight-base;
            margin: 0;
            padding: 0;
            line-height: 1;

            @include media-breakpoint-down(md) {
                font-size: $font-size-base * 1.25;
            }
        } // &__sub

        &__bg {
            display: block;

            @include no-select;
            max-width: 100%;
            height: auto;

            @include media-breakpoint-down(md) {
                max-width: 50%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 2rem;
            }
        } // &__bg
    } // &-hero

    &-about {
        padding-top: 4rem;
        padding-bottom: 4rem;

        &__lead {
            display: block;

            color: $black;
            font-size: $font-size-base * 1.25;
            font-weight: $font-weight-base;
            line-height: 1.7;

            margin: 0;
            padding: 0;

            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

        } // &__lead
    } // &-about

    &-promo {
        margin-top: 2rem;

        &-panda {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;

            background-position: right center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 100%;

            padding: 2rem 1rem;
            border-radius: 0.5rem;

            &__head {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;

                background: $black;
                border-radius: 0.25rem;
                padding: 0.5rem 0.75rem;

                margin-bottom: 2rem;

                span {
                    color: $white;
                    display: block;
                    font-size: $font-size-base;
                    font-weight: $font-weight-base;
                    line-height: 1.2;

                    margin-right: 0.5rem;
                }

                img {
                    display: block;
                    height: 0.75rem;
                    width: auto;
                }
            }

            &__sub {
                display: block;

                color: $white;
                font-size: $font-size-lg * 1.25;
                font-weight: $font-weight-bold;
                line-height: 1.4;

                margin: 0;
                padding: 0;
                text-shadow: 0 2px 2px opacity($black, 0.1);

                margin-bottom: 1rem;
            }

            &__btn {
                display: block;
                position: relative;
                overflow: hidden;

                color: $black;
                background: #ffe600;

                box-shadow: 0 1.5rem 2rem -0.5rem opacity(darken($teal, 30%), 0.3);

                font-size: $font-size-lg;
                font-weight: $font-weight-bold;
                line-height: 1;
                margin: 0;
                padding: 1rem 1.5rem;
                border-radius: 25rem;

                transition: $transition-base;

                &:before {
                    content: '';

                    position: absolute;
                    top: 0;
                    left: 0;

                    background: linear-gradient(
                        -92deg,
                        opacity($white, 0.7),
                        opacity($white, 0.7)
                    );

                    width: 3rem;
                    height: 100%;

                    transform: skewX(-42deg) translateX(-10%);
                    z-index: 1;
                    will-change: transform;

                    opacity: 0;

                    transition: all 0.3s ease;
                } // &:before

                &:hover {
                    color: $black;
                    text-decoration: none;
                    transform: translateY(-10px);

                    box-shadow: 0 2.5rem 2rem -1rem opacity(darken($teal, 30%), 0.6);

                    &:before {
                        transform: skewX(-42deg) translateX(520%);
                        opacity: 1;
                    } // &:before
                }
            }
        }

        &__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            background: $teal;

            padding-top: 2rem;
            padding-bottom: 2rem;

            border-radius: 0.5rem;

            background-image: url('../images/home/money_pattern.svg');
            background-repeat: repeat-y;
            background-size: cover;

            height: 100%;
        } // &__wrap

        &__head {
            display: block;

            color: $white;
            font-size: $font-size-lg * 2;
            font-weight: $font-weight-bold;
            line-height: 1;
            text-shadow: 0 2px 2px opacity($black, 0.1);
        } // &__head

        &__sub {
            display: block;

            color: lighten($teal, 30%);
            font-size: $font-size-base * 1.25;
            font-weight: $font-weight-base;
            line-height: 1;

            margin-bottom: 1rem;
        } // &__sub

        &__btn {
            display: block;
            position: relative;
            overflow: hidden;

            color: $black;
            background: #ffe600;

            box-shadow: 0 1.5rem 2rem -0.5rem opacity(darken($teal, 30%), 0.3);

            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin: 0;
            padding: 1rem 1.5rem;
            border-radius: 25rem;

            transition: $transition-base;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                background: linear-gradient(
                    -92deg,
                    opacity($white, 0.7),
                    opacity($white, 0.7)
                );

                width: 3rem;
                height: 100%;

                transform: skewX(-42deg) translateX(-10%);
                z-index: 1;
                will-change: transform;

                opacity: 0;

                transition: all 0.3s ease;
            } // &:before

            &:hover {
                color: $black;
                text-decoration: none;
                transform: translateY(-10px);

                box-shadow: 0 2.5rem 2rem -1rem opacity(darken($teal, 30%), 0.6);

                &:before {
                    transform: skewX(-42deg) translateX(520%);
                    opacity: 1;
                } // &:before
            }
        }
    } // &-promo

    &-reviews {
        padding-top: 3rem;
        padding-bottom: 3rem;

        &__item {
            background: $white;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            border: 1px solid $gray-200;
            border-radius: 0.5rem;

            padding: 1.5rem 1rem;

            margin-top: 1rem;
        } // &__item

        &__img {
            @include no-select;
            display: block;
            max-width: 40%;
            height: auto;
            border-radius: 50rem;

            margin-bottom: 1rem;
        } // &__img

        &__head {
            display: block;

            color: $black;
            font-size: $font-size-lg * 1.25;
            font-weight: $font-weight-medium;
            line-height: 1;

            margin: 0;
            padding: 0;

            margin-bottom: 0.25rem;
        } // &__head

        &__subhead {
            display: block;
            color: $gray-600;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            font-style: italic;

            margin: 0;
            padding: 0;

            margin-bottom: 1rem;
        } // &__subhead

        &__sub {
            display: block;

            color: $black;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1.6;

            margin: 0;
            padding: 0;

            margin-bottom: 1rem;

            width: 100%;
            max-height: 15rem;

            @include media-breakpoint-down(md) {
                text-align: justify;
            }
        } // &__sub
        &__link {
            display: block;
            text-decoration: none;

            color: $black;
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
            line-height: 1;

            margin: 0;
            padding: 0;

            position: relative;
            overflow: hidden;

            background: $white;
            padding: 0.5rem 1.25rem;
            border: 2px solid lighten($blue, 10%);
            border-radius: 25rem;

            box-shadow: 0 0.5rem 2rem -0.25rem opacity(darken($blue, 10%), 0.3);

            transition: $transition-base;

            &:before {
                content: '';

                position: absolute;
                top: 0;
                left: 0;

                background: linear-gradient(
                    -92deg,
                    opacity($blue, 0.7),
                    opacity($blue, 0.7)
                );

                width: 3rem;
                height: 100%;

                transform: skewX(-42deg) translateX(-10%);
                z-index: 1;
                will-change: transform;

                opacity: 0;

                transition: all 0.3s ease;
            } // &:before

            &:hover {
                color: $white;
                background: $blue;
                border-color: $blue;

                text-decoration: none;
                transform: translateY(-4px);

                box-shadow: 0 1.5rem 1.5rem -0.75rem opacity(darken($blue, 10%), 0.7);

                &:before {
                    transform: skewX(-42deg) translateX(460%);
                    opacity: 1;
                } // &:before
            }
        }
    } // &-reviwes
} // .home