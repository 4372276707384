@charset "UTF-8";
:root {
  --blue: #124BFF;
  --indigo: #6610f2;
  --purple: #ac71de;
  --pink: #f5576c;
  --red: #dc3545;
  --orange: #FFBB19;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #56BF98;
  --cyan: #26d0d0;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #124BFF;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #26d0d0;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1366px;
  --breakpoint-fhd: 1920px;
  --font-family-sans-serif: "Futura New", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Futura New", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #124BFF;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #002fc5;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

main {
  flex: 1; }

@page {
  size: auto;
  margin: 0; }

@font-face {
  font-family: 'Futura New';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/FuturaNew-Light.woff2") format("woff2"), url("../fonts/FuturaNew-Light.woff") format("woff"); }

@font-face {
  font-family: 'Futura New';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/FuturaNew-Regular.woff2") format("woff2"), url("../fonts/FuturaNew-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Futura New';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/FuturaNew-Medium.woff2") format("woff2"), url("../fonts/FuturaNew-Medium.woff") format("woff"); }

@font-face {
  font-family: 'Futura New';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/FuturaNew-Bold.woff2") format("woff2"), url("../fonts/FuturaNew-Bold.woff") format("woff"); }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #f5576c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1366px) {
    .container {
      max-width: 1306px; } }
  @media (min-width: 1920px) {
    .container {
      max-width: 1860px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-fhd-1, .col-fhd-2, .col-fhd-3, .col-fhd-4, .col-fhd-5, .col-fhd-6, .col-fhd-7, .col-fhd-8, .col-fhd-9, .col-fhd-10, .col-fhd-11, .col-fhd-12, .col-fhd,
.col-fhd-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1366px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-fhd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-fhd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-fhd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-fhd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-fhd-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-fhd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-fhd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-fhd-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-fhd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-fhd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-fhd-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-fhd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-fhd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-fhd-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-fhd-first {
    order: -1; }
  .order-fhd-last {
    order: 13; }
  .order-fhd-0 {
    order: 0; }
  .order-fhd-1 {
    order: 1; }
  .order-fhd-2 {
    order: 2; }
  .order-fhd-3 {
    order: 3; }
  .order-fhd-4 {
    order: 4; }
  .order-fhd-5 {
    order: 5; }
  .order-fhd-6 {
    order: 6; }
  .order-fhd-7 {
    order: 7; }
  .order-fhd-8 {
    order: 8; }
  .order-fhd-9 {
    order: 9; }
  .order-fhd-10 {
    order: 10; }
  .order-fhd-11 {
    order: 11; }
  .order-fhd-12 {
    order: 12; }
  .offset-fhd-0 {
    margin-left: 0; }
  .offset-fhd-1 {
    margin-left: 8.33333%; }
  .offset-fhd-2 {
    margin-left: 16.66667%; }
  .offset-fhd-3 {
    margin-left: 25%; }
  .offset-fhd-4 {
    margin-left: 33.33333%; }
  .offset-fhd-5 {
    margin-left: 41.66667%; }
  .offset-fhd-6 {
    margin-left: 50%; }
  .offset-fhd-7 {
    margin-left: 58.33333%; }
  .offset-fhd-8 {
    margin-left: 66.66667%; }
  .offset-fhd-9 {
    margin-left: 75%; }
  .offset-fhd-10 {
    margin-left: 83.33333%; }
  .offset-fhd-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bdcdff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #84a1ff; }

.table-hover .table-primary:hover {
  background-color: #a4baff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a4baff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2f2f2; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8ee7e7; }

.table-hover .table-info:hover {
  background-color: #adeeee; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #adeeee; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1365.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

@media (max-width: 1919.98px) {
  .table-responsive-fhd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-fhd > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #92acff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #124BFF;
  border-color: #124BFF; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0038eb;
    border-color: #0035de; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 102, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #124BFF;
    border-color: #124BFF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0035de;
    border-color: #0032d1; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 102, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #212529;
  background-color: #26d0d0;
  border-color: #26d0d0; }
  .btn-info:hover {
    color: #fff;
    background-color: #20b0b0;
    border-color: #1ea5a5; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(37, 182, 183, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #26d0d0;
    border-color: #26d0d0; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1ea5a5;
    border-color: #1c9a9a; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(37, 182, 183, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #124BFF;
  border-color: #124BFF; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #124BFF;
    border-color: #124BFF; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #124BFF;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #124BFF;
    border-color: #124BFF; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #26d0d0;
  border-color: #26d0d0; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #26d0d0;
    border-color: #26d0d0; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 208, 208, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #26d0d0;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #26d0d0;
    border-color: #26d0d0; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 208, 208, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #124BFF;
  text-decoration: none; }
  .btn-link:hover {
    color: #002fc5;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1366px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1920px) {
  .dropdown-menu-fhd-left {
    right: auto;
    left: 0; }
  .dropdown-menu-fhd-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #124BFF; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #124BFF;
    background-color: #124BFF; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #92acff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c5d3ff;
    border-color: #c5d3ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #124BFF;
  background-color: #124BFF; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 75, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(18, 75, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 75, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(18, 75, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #92acff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #92acff;
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #124BFF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #c5d3ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #124BFF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #c5d3ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #124BFF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #c5d3ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.header {
  transition: all 0.2s ease-in-out; }
  .header__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media (min-width: 576px) {
      .header__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .header__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .header__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .header__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .header__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .header__wrap {
        max-width: 1860px; } }
  .header-logo {
    display: block;
    text-decoration: none;
    border: none;
    outline: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .header-logo:hover .header-logo__img {
      fill: #ac71de; }
    @media (max-width: 991.98px) {
      .header-logo {
        order: 1; } }
    .header-logo.is-active {
      user-select: none;
      pointer-events: none; }
    .header-logo__img {
      display: block;
      width: 10rem;
      height: auto;
      fill: #000;
      transition: all 0.2s ease-in-out; }
      @media (max-width: 991.98px) {
        .header-logo__img {
          width: 8rem; } }
  .header__training {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background: #6d597a;
    position: relative;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    margin-left: 2rem;
    transition: all 0.2s ease-in-out; }
    .header__training .sub {
      display: block;
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.85rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      margin-bottom: 0.125rem;
      transition: all 0.2s ease-in-out; }
    .header__training .text {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      color: #fff;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .header__training:hover {
      background: rgba(109, 89, 122, 0.75);
      text-decoration: none;
      transform: translateY(-3px);
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(83, 67, 93, 0.6); }
    @media (max-width: 1199.98px) {
      .header__training {
        order: 10;
        flex: 1;
        min-width: 100%;
        margin-left: 0;
        margin-top: 1rem; } }
  .header-toggler {
    position: relative;
    display: none;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s ease-in-out;
    margin-left: auto; }
    @media (max-width: 991.98px) {
      .header-toggler {
        display: block;
        order: 3; } }
    .header-toggler__toggle {
      position: relative;
      display: block;
      height: 100%;
      width: 100%; }
      .header-toggler__toggle:before, .header-toggler__toggle:after {
        content: '';
        position: absolute;
        background: #000;
        height: 3px;
        width: 2rem;
        transition: all 0.2s ease-in-out;
        z-index: 9999;
        left: .5rem;
        transform-origin: 50% 50%; }
      .header-toggler__toggle:before {
        top: 1rem; }
      .header-toggler__toggle:after {
        bottom: 1rem; }
  .header__insta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-left: 1px solid #ced4da;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    position: relative; }
    @media (max-width: 991.98px) {
      .header__insta {
        border: none;
        margin-right: auto;
        order: 2; } }
    .header__insta .text {
      color: #000;
      transition: all 0.2s ease-in-out;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1; }
      @media (max-width: 991.98px) {
        .header__insta .text {
          display: none; } }
    .header__insta .icon {
      display: block;
      font-size: 1.5rem;
      margin-right: 0.5rem;
      margin-bottom: -0.125rem;
      color: #ac71de;
      transition: all 0.2s ease-in-out; }
    .header__insta:hover {
      text-decoration: none; }
      .header__insta:hover .text {
        color: #ac71de; }
      .header__insta:hover .icon {
        color: #ac71de; }
  .header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-top: 1px solid #f8f9fa; }
    @media (max-width: 991.98px) {
      .header-menu {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        order: 3;
        display: none; } }
    .header-menu__link {
      display: block;
      color: #000;
      font-size: 1.125rem;
      font-weight: 500;
      padding: 1.125rem 1.25rem 1.25rem 1.25rem;
      letter-spacing: 0.75px;
      line-height: 1;
      transition: all 0.2s ease-in-out; }
      .header-menu__link:first-child {
        padding-left: 0; }
      .header-menu__link:last-child {
        padding-right: 0; }
      .header-menu__link.is-active {
        pointer-events: none;
        color: #333333; }
      @media (max-width: 991.98px) {
        .header-menu__link {
          padding-left: 0;
          padding-right: 0; } }
      .header-menu__link svg {
        display: inline-block;
        height: 2rem;
        width: auto; }
      .header-menu__link.is-active {
        color: #adb5bd;
        pointer-events: none;
        position: relative; }
      .header-menu__link.is-disabled {
        color: rgba(0, 0, 0, 0.1);
        pointer-events: none; }
      .header-menu__link:hover {
        text-decoration: none;
        color: #ac71de; }
    .header-menu__badge {
      display: inline;
      background: #f5576c;
      color: #fff;
      font-size: 0.5rem;
      font-weight: 400;
      line-height: 1;
      padding: 0.125rem 0.375rem;
      border-radius: 50rem;
      margin: 0;
      position: relative;
      top: -0.125rem;
      text-transform: uppercase; }
  .header__btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    color: #ac71de;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    background: transparent;
    border: 2px solid #ac71de;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out; }
    .header__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(220, 196, 241, 0.7), rgba(220, 196, 241, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .header__btn:hover {
      text-decoration: none;
      color: #fff;
      background: #ac71de;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(148, 72, 212, 0.6); }
      .header__btn:hover:before {
        transform: skewX(-42deg) translateX(410%);
        opacity: 1; }
    @media (max-width: 991.98px) {
      .header__btn {
        display: none;
        order: 5;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        width: 100%;
        text-align: center; }
        .header__btn:hover:before {
          transform: skewX(-42deg) translateX(810%); } }
  .header-clients {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    border-right: 1px solid #e9ecef;
    margin-left: auto;
    margin-right: 1rem;
    padding-right: 1rem; }
    @media (max-width: 991.98px) {
      .header-clients {
        display: none; } }
    .header-clients__head {
      display: block;
      color: #6c757d;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      padding: 0;
      line-height: 1; }
    .header-clients__sub {
      display: block;
      color: #6c757d;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      padding: 0;
      line-height: 1; }
  .header.open .header-toggler__toggle:before {
    top: 50%;
    transform: rotate(45deg) translateY(-50%); }
  .header.open .header-toggler__toggle:after {
    bottom: 50%;
    transform: rotate(-45deg) translateY(50%); }
  .header.open .header-menu {
    display: flex; }
  .header.open .header__btn {
    display: block; }
  .header.open .header__insta {
    display: flex; }

.footer {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .footer-wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between; }
    @media (min-width: 576px) {
      .footer-wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .footer-wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .footer-wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .footer-wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .footer-wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .footer-wrap {
        max-width: 1860px; } }
    @media (max-width: 991.98px) {
      .footer-wrap {
        flex-direction: column;
        align-items: center; } }
  .footer-copy {
    display: block;
    color: #ced4da;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1; }
    @media (max-width: 991.98px) {
      .footer-copy {
        font-size: 1rem; } }
  .footer-link {
    display: block;
    text-decoration: none;
    color: #ced4da;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out; }
    .footer-link:hover {
      text-decoration: none;
      color: #ac71de; }
    @media (max-width: 991.98px) {
      .footer-link {
        font-size: 1rem; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #124BFF; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1365.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1366px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

@media (max-width: 1919.98px) {
  .navbar-expand-fhd > .container,
  .navbar-expand-fhd > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1920px) {
  .navbar-expand-fhd {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-fhd .navbar-nav {
      flex-direction: row; }
      .navbar-expand-fhd .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-fhd .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-fhd > .container,
    .navbar-expand-fhd > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-fhd .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-fhd .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #124BFF;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #002fc5;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 75, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #124BFF;
  border-color: #124BFF; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #092785;
  background-color: #d0dbff;
  border-color: #bdcdff; }
  .alert-primary .alert-link {
    color: #061955; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #146c6c;
  background-color: #d4f6f6;
  border-color: #c2f2f2; }
  .alert-info .alert-link {
    color: #0c4141; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #124BFF;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #124BFF;
    border-color: #124BFF; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1366px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1920px) {
  .list-group-horizontal-fhd {
    flex-direction: row; }
    .list-group-horizontal-fhd .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-fhd .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-fhd .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #092785;
  background-color: #bdcdff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #092785;
    background-color: #a4baff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #092785;
    border-color: #092785; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #146c6c;
  background-color: #c2f2f2; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #146c6c;
    background-color: #adeeee; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #146c6c;
    border-color: #146c6c; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Futura New", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Futura New", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #124BFF !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

.bg-info {
  background-color: #26d0d0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #124BFF !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #26d0d0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1366px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1920px) {
  .d-fhd-none {
    display: none !important; }
  .d-fhd-inline {
    display: inline !important; }
  .d-fhd-inline-block {
    display: inline-block !important; }
  .d-fhd-block {
    display: block !important; }
  .d-fhd-table {
    display: table !important; }
  .d-fhd-table-row {
    display: table-row !important; }
  .d-fhd-table-cell {
    display: table-cell !important; }
  .d-fhd-flex {
    display: flex !important; }
  .d-fhd-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1366px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1920px) {
  .flex-fhd-row {
    flex-direction: row !important; }
  .flex-fhd-column {
    flex-direction: column !important; }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-fhd-wrap {
    flex-wrap: wrap !important; }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-fhd-fill {
    flex: 1 1 auto !important; }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-fhd-start {
    justify-content: flex-start !important; }
  .justify-content-fhd-end {
    justify-content: flex-end !important; }
  .justify-content-fhd-center {
    justify-content: center !important; }
  .justify-content-fhd-between {
    justify-content: space-between !important; }
  .justify-content-fhd-around {
    justify-content: space-around !important; }
  .align-items-fhd-start {
    align-items: flex-start !important; }
  .align-items-fhd-end {
    align-items: flex-end !important; }
  .align-items-fhd-center {
    align-items: center !important; }
  .align-items-fhd-baseline {
    align-items: baseline !important; }
  .align-items-fhd-stretch {
    align-items: stretch !important; }
  .align-content-fhd-start {
    align-content: flex-start !important; }
  .align-content-fhd-end {
    align-content: flex-end !important; }
  .align-content-fhd-center {
    align-content: center !important; }
  .align-content-fhd-between {
    align-content: space-between !important; }
  .align-content-fhd-around {
    align-content: space-around !important; }
  .align-content-fhd-stretch {
    align-content: stretch !important; }
  .align-self-fhd-auto {
    align-self: auto !important; }
  .align-self-fhd-start {
    align-self: flex-start !important; }
  .align-self-fhd-end {
    align-self: flex-end !important; }
  .align-self-fhd-center {
    align-self: center !important; }
  .align-self-fhd-baseline {
    align-self: baseline !important; }
  .align-self-fhd-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1366px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

@media (min-width: 1920px) {
  .float-fhd-left {
    float: left !important; }
  .float-fhd-right {
    float: right !important; }
  .float-fhd-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1366px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

@media (min-width: 1920px) {
  .m-fhd-0 {
    margin: 0 !important; }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important; }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important; }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important; }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important; }
  .m-fhd-1 {
    margin: 0.25rem !important; }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important; }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important; }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important; }
  .m-fhd-2 {
    margin: 0.5rem !important; }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important; }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important; }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important; }
  .m-fhd-3 {
    margin: 1rem !important; }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important; }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important; }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important; }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important; }
  .m-fhd-4 {
    margin: 1.5rem !important; }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important; }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important; }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important; }
  .m-fhd-5 {
    margin: 3rem !important; }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 3rem !important; }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 3rem !important; }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 3rem !important; }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 3rem !important; }
  .p-fhd-0 {
    padding: 0 !important; }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important; }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important; }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important; }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important; }
  .p-fhd-1 {
    padding: 0.25rem !important; }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important; }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important; }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important; }
  .p-fhd-2 {
    padding: 0.5rem !important; }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important; }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important; }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important; }
  .p-fhd-3 {
    padding: 1rem !important; }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important; }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important; }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important; }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important; }
  .p-fhd-4 {
    padding: 1.5rem !important; }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important; }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important; }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important; }
  .p-fhd-5 {
    padding: 3rem !important; }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 3rem !important; }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 3rem !important; }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 3rem !important; }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 3rem !important; }
  .m-fhd-n1 {
    margin: -0.25rem !important; }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important; }
  .m-fhd-n2 {
    margin: -0.5rem !important; }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.5rem !important; }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.5rem !important; }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.5rem !important; }
  .m-fhd-n3 {
    margin: -1rem !important; }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -1rem !important; }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -1rem !important; }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -1rem !important; }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -1rem !important; }
  .m-fhd-n4 {
    margin: -1.5rem !important; }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -1.5rem !important; }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -1.5rem !important; }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -1.5rem !important; }
  .m-fhd-n5 {
    margin: -3rem !important; }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -3rem !important; }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -3rem !important; }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -3rem !important; }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -3rem !important; }
  .m-fhd-auto {
    margin: auto !important; }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important; }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important; }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important; }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1366px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1920px) {
  .text-fhd-left {
    text-align: left !important; }
  .text-fhd-right {
    text-align: right !important; }
  .text-fhd-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #124BFF !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #002fc5 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #26d0d0 !important; }

a.text-info:hover, a.text-info:focus {
  color: #1a8f8f !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

body.home .header {
  background: #f9f5fd; }
  body.home .header__insta {
    border-left-color: #f0e5f9; }
  body.home .header-clients {
    border-right-color: #f0e5f9; }
    body.home .header-clients__head, body.home .header-clients__sub {
      color: #ac71de; }
  body.home .header-menu {
    border-top-color: #f0e5f9;
    transition: all 0.2s ease-in-out; }
  @media (max-width: 991.98px) {
    body.home .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(148, 72, 212, 0.6); }
      body.home .header.open .header-logo .light {
        fill: #e9ecef; }
      body.home .header.open .header__insta svg {
        fill: #dee2e6; }
      body.home .header.open .header__insta:hover svg {
        fill: #ac71de; }
      body.home .header.open .header-menu {
        border-top-color: #e9ecef; } }

.home__img {
  display: block;
  height: 3rem;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  user-select: none;
  pointer-events: none; }

.home__subhead {
  display: block;
  color: #ac71de;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  padding: 0;
  margin: 0; }
  @media (max-width: 991.98px) {
    .home__subhead {
      font-size: 1.125rem;
      margin-bottom: 0.125rem; } }

.home__head {
  display: block;
  color: #000;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .home__head {
      font-size: 1.5625rem; }
      .home__head br {
        display: none; } }

.home-hero {
  padding-top: 2rem;
  background: linear-gradient(#f9f5fd, #fff); }
  @media (max-width: 991.98px) {
    .home-hero {
      text-align: center; } }
  .home-hero__img {
    user-select: none;
    pointer-events: none;
    max-width: 100%;
    height: auto;
    display: block; }
  .home-hero__head {
    display: block;
    color: #000;
    font-size: 2.8125rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .home-hero__head {
        font-size: 2.5rem; } }
  .home-hero__sub {
    display: block;
    color: #ac71de;
    font-size: 1.875rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1; }
    @media (max-width: 991.98px) {
      .home-hero__sub {
        font-size: 1.25rem; } }
  .home-hero__bg {
    display: block;
    user-select: none;
    pointer-events: none;
    max-width: 100%;
    height: auto; }
    @media (max-width: 991.98px) {
      .home-hero__bg {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem; } }

.home-about {
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .home-about__lead {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .home-about__lead:last-child {
      margin-bottom: 0; }

.home-promo {
  margin-top: 2rem; }
  .home-promo-panda {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 2rem 1rem;
    border-radius: 0.5rem; }
    .home-promo-panda__head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      background: #000;
      border-radius: 0.25rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 2rem; }
      .home-promo-panda__head span {
        color: #fff;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        margin-right: 0.5rem; }
      .home-promo-panda__head img {
        display: block;
        height: 0.75rem;
        width: auto; }
    .home-promo-panda__sub {
      display: block;
      color: #fff;
      font-size: 1.5625rem;
      font-weight: 700;
      line-height: 1.4;
      margin: 0;
      padding: 0;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem; }
    .home-promo-panda__btn {
      display: block;
      position: relative;
      overflow: hidden;
      color: #000;
      background: #ffe600;
      box-shadow: 0 1.5rem 2rem -0.5rem rgba(34, 90, 69, 0.3);
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      padding: 1rem 1.5rem;
      border-radius: 25rem;
      transition: all 0.2s ease-in-out; }
      .home-promo-panda__btn:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
        width: 3rem;
        height: 100%;
        transform: skewX(-42deg) translateX(-10%);
        z-index: 1;
        will-change: transform;
        opacity: 0;
        transition: all 0.3s ease; }
      .home-promo-panda__btn:hover {
        color: #000;
        text-decoration: none;
        transform: translateY(-10px);
        box-shadow: 0 2.5rem 2rem -1rem rgba(34, 90, 69, 0.6); }
        .home-promo-panda__btn:hover:before {
          transform: skewX(-42deg) translateX(520%);
          opacity: 1; }
  .home-promo__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #56BF98;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 0.5rem;
    background-image: url("../images/home/money_pattern.svg");
    background-repeat: repeat-y;
    background-size: cover;
    height: 100%; }
  .home-promo__head {
    display: block;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); }
  .home-promo__sub {
    display: block;
    color: #c5e9dc;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 1rem; }
  .home-promo__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    box-shadow: 0 1.5rem 2rem -0.5rem rgba(34, 90, 69, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    transition: all 0.2s ease-in-out; }
    .home-promo__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .home-promo__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(34, 90, 69, 0.6); }
      .home-promo__btn:hover:before {
        transform: skewX(-42deg) translateX(520%);
        opacity: 1; }

.home-reviews {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .home-reviews__item {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #e9ecef;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem;
    margin-top: 1rem; }
  .home-reviews__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 40%;
    height: auto;
    border-radius: 50rem;
    margin-bottom: 1rem; }
  .home-reviews__head {
    display: block;
    color: #000;
    font-size: 1.5625rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.25rem; }
  .home-reviews__subhead {
    display: block;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
  .home-reviews__sub {
    display: block;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
    max-height: 15rem; }
    @media (max-width: 991.98px) {
      .home-reviews__sub {
        text-align: justify; } }
  .home-reviews__link {
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 0.5rem 1.25rem;
    border: 2px solid #4572ff;
    border-radius: 25rem;
    box-shadow: 0 0.5rem 2rem -0.25rem rgba(0, 53, 222, 0.3);
    transition: all 0.2s ease-in-out; }
    .home-reviews__link:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(18, 75, 255, 0.7), rgba(18, 75, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .home-reviews__link:hover {
      color: #fff;
      background: #124BFF;
      border-color: #124BFF;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1.5rem 1.5rem -0.75rem rgba(0, 53, 222, 0.7); }
      .home-reviews__link:hover:before {
        transform: skewX(-42deg) translateX(460%);
        opacity: 1; }

body.money .header {
  background: #f5fbf9; }
  body.money .header-logo__img {
    fill: #000; }
  body.money .header__insta {
    border-left-color: #d7f0e7; }
    body.money .header__insta .icon {
      color: #56BF98; }
    body.money .header__insta:hover .icon {
      fill: #ac71de; }
  body.money .header-clients {
    border-right-color: #d7f0e7; }
    body.money .header-clients__head, body.money .header-clients__sub {
      color: #7bcdaf; }
  body.money .header-menu {
    border-top-color: #d7f0e7;
    transition: all 0.2s ease-in-out; }
  body.money .header__btn {
    border-color: #56BF98;
    color: #56BF98; }
    body.money .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.money .header__btn:hover {
      color: #fff;
      background: #ac71de;
      border-color: #ac71de; }
  @media (max-width: 991.98px) {
    body.money .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(242, 39, 66, 0.6); }
      body.money .header.open .header-logo .light {
        fill: #e9ecef; }
      body.money .header.open .header__insta svg {
        fill: #dee2e6; }
      body.money .header.open .header__insta:hover svg {
        fill: #ac71de; }
      body.money .header.open .header-menu {
        border-top-color: #e9ecef; } }

.money-video {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
  color: #56BF98;
  background: #fff;
  border: 2px solid #c5e9dc;
  box-shadow: 0 0 0 0 rgba(86, 191, 152, 0);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1.25rem;
  border-radius: 50rem;
  margin-top: 1rem;
  transition: all 0.2s ease-in-out; }
  .money-video svg {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 1.5rem;
    width: auto;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    fill: #56BF98; }
  .money-video:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
    width: 3rem;
    height: 100%;
    transform: skewX(-42deg) translateX(-10%);
    z-index: 1;
    will-change: transform;
    opacity: 0;
    transition: all 0.3s ease; }
  .money-video:hover {
    color: #fff;
    background: #f5576c;
    border-color: #f5576c;
    text-decoration: none;
    transform: translateY(-4px);
    box-shadow: 0 1rem 2rem -1rem rgba(245, 87, 108, 0.6); }
    .money-video:hover svg {
      fill: #fbb7c0; }
    .money-video:hover:before {
      transform: skewX(-42deg) translateX(900%);
      opacity: 1; }
  .money-video:focus, .money-video:active {
    outline: none; }

.money__head {
  display: block;
  color: #56BF98;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.money__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.money__lead {
  display: block;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1; }
  .money__lead a {
    color: #56BF98;
    border-bottom: 1px solid rgba(86, 191, 152, 0);
    transition: all 0.2s ease-in-out; }
    .money__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.money-hero {
  background: linear-gradient(#f5fbf9, #fff); }
  .money-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .money-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .money-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .money-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .money-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .money-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .money-hero__wrap {
        max-width: 1860px; } }
  .money-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .money-hero__head {
        font-size: 3.75rem; } }
  .money-hero__sub {
    display: block;
    color: #56BF98;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .money-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.money-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.money-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #c5e9dc;
  border-radius: 0.5rem; }
  .money-div--center {
    align-items: center; }
  .money-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(86, 191, 152, 0.05), rgba(86, 191, 152, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.money-form {
  background: #56BF98;
  background: linear-gradient(45deg, #56BF98, #56babf);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .money-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .money-form .money__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .money-form .money__head {
        font-size: 1.875rem; } }
  .money-form .money__sub {
    color: #c5e9dc;
    display: block;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem; }
  .money-form__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 2rem; }
  .money-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .money-form__item {
        width: 5rem; } }
    .money-form__item-last {
      width: 12rem; }
      @media (max-width: 991.98px) {
        .money-form__item-last {
          width: 7rem; } }
  .money-form__label {
    display: block;
    color: #c5e9dc;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .money-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .money-form__input {
        font-size: 1.875rem; } }
    .money-form__input:focus, .money-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .money-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .money-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .money-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(34, 90, 69, 0.6); }
      .money-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .money-form__btn:focus, .money-form__btn:active {
      outline: none; }
  .money-form__help {
    display: block;
    color: #a0dbc5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .money-form__help a {
      color: #a0dbc5;
      text-decoration: none;
      border-bottom: 1px solid rgba(160, 219, 197, 0.23);
      transition: all 0.2s ease-in-out; }
      .money-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.money-contact {
  background: #f1faf7;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.money-result-hero {
  background: #56BF98;
  padding-top: 4rem; }
  .money-result-hero__back {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start; }
    .money-result-hero__back:hover {
      text-decoration: none; }
      .money-result-hero__back:hover .money-result-hero__arr {
        fill: #a0dbc5; }
      .money-result-hero__back:hover .money-result-hero__link {
        text-decoration: none;
        color: #a0dbc5; }
  .money-result-hero__arr {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
    fill: #fff;
    transition: all 0.2s ease-in-out; }
  .money-result-hero__link {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out; }
  .money-result-hero__logo {
    display: block;
    width: 10rem;
    height: auto;
    margin: 0 auto; }
    @media (max-width: 991.98px) {
      .money-result-hero__logo {
        width: 8rem; } }
    @media (max-width: 1199.98px) {
      .money-result-hero__logo {
        margin-left: auto;
        margin-right: 0; } }
  .money-result-hero__top {
    display: block;
    background: #fff;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 1.40625rem;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    padding: 2rem 1rem;
    margin-top: 2rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }

.money-result__head {
  display: block;
  color: #56BF98;
  font-size: 7.5rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1.2;
  text-align: center;
  margin: 0;
  padding: 0;
  margin: 2rem auto; }

.money-result__sub {
  display: block;
  color: #000;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem; }
  .money-result__sub strong {
    font-weight: 700;
    color: #56BF98; }

.money-result__list {
  color: #000;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1rem; }

.money-result__group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto; }
  .money-result__group:last-child {
    margin-top: 4rem;
    margin-bottom: 4rem; }

.money-result__btn {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: #56BF98;
  background: #fff;
  border: 2px solid #c5e9dc;
  box-shadow: 0 0 0 0 rgba(86, 191, 152, 0);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1.25rem;
  border-radius: 50rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out; }
  .money-result__btn svg {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 1.5rem;
    width: auto;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    fill: #56BF98; }
  .money-result__btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-92deg, rgba(141, 212, 186, 0.7), rgba(141, 212, 186, 0.7));
    width: 3rem;
    height: 100%;
    transform: skewX(-42deg) translateX(-10%);
    z-index: 1;
    will-change: transform;
    opacity: 0;
    transition: all 0.3s ease; }
  .money-result__btn:hover {
    color: #fff;
    background: #56BF98;
    border-color: #56BF98;
    text-decoration: none;
    transform: translateY(-4px);
    box-shadow: 0 1rem 2rem -1rem rgba(86, 191, 152, 0.6); }
    .money-result__btn:hover svg {
      fill: #a0dbc5; }
    .money-result__btn:hover:before {
      transform: skewX(-42deg) translateX(510%);
      opacity: 1; }
  .money-result__btn:focus, .money-result__btn:active {
    outline: none; }

.money-result__ps {
  background: #d7f0e7;
  border-radius: 0.5rem;
  padding: 2rem 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem; }
  .money-result__ps .money-result__btn {
    margin: 0; }
  .money-result__ps .money-result__group {
    padding: 0;
    margin: 0; }

body.business .header {
  background: #eafbfb; }
  body.business .header-logo svg * {
    transition: all 0.2s ease-in-out; }
  body.business .header-logo svg .light {
    fill: #8ceaea; }
  body.business .header-logo:hover svg .dark {
    fill: #ac71de; }
  body.business .header-logo:hover svg .light {
    fill: #f5edfb; }
  body.business .header__insta {
    border-left-color: #ccf6f6; }
    body.business .header__insta svg {
      fill: #76e6e6; }
    body.business .header__insta span {
      color: #4bdede; }
    body.business .header__insta:hover svg {
      fill: #ac71de; }
    body.business .header__insta:hover span {
      color: #ac71de; }
  body.business .header-clients {
    border-right-color: #ccf6f6; }
    body.business .header-clients__head, body.business .header-clients__sub {
      color: #4bdede; }
  body.business .header-menu {
    border-top-color: #ccf6f6;
    transition: all 0.2s ease-in-out; }
  body.business .header__btn {
    background: #26d0d0; }
    body.business .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.business .header__btn:hover {
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.business .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(242, 39, 66, 0.6); }
      body.business .header.open .header-logo .light {
        fill: #e9ecef; }
      body.business .header.open .header__insta svg {
        fill: #dee2e6; }
      body.business .header.open .header__insta:hover svg {
        fill: #f5576c; }
      body.business .header.open .header-menu {
        border-top-color: #e9ecef; } }

.business__head {
  display: block;
  color: #167a7a;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.business__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.business__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .business__lead a {
    color: #26d0d0;
    border-bottom: 1px solid rgba(38, 208, 208, 0);
    transition: all 0.2s ease-in-out; }
    .business__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.business-hero {
  background: linear-gradient(#eafbfb, #fff); }
  .business-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .business-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .business-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .business-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .business-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .business-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .business-hero__wrap {
        max-width: 1860px; } }
  .business-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .business-hero__head {
        font-size: 3.75rem; } }
  .business-hero__sub {
    display: block;
    color: #26d0d0;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .business-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.business-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.business-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #a1eeee;
  border-radius: 0.5rem; }
  .business-div--center {
    align-items: center; }
  .business-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(38, 208, 208, 0.05), rgba(38, 208, 208, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.business-form {
  background: #26d0d0;
  background: linear-gradient(45deg, #26d0d0, #bf5695);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .business-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .business-form .business__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .business-form .business__head {
        font-size: 1.875rem; } }
  .business-form .business__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .business-form .business__sub {
        max-width: 100%; } }
  .business-form__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 1rem; }
  .business-form__bot {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 2rem; }
    .business-form__bot .business-form__item {
      width: 16.5rem; }
    @media (max-width: 991.98px) {
      .business-form__bot {
        flex-direction: column; }
        .business-form__bot .business-form__item {
          margin-bottom: 1rem;
          width: 100%;
          margin-right: 0; } }
  .business-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .business-form__item {
        width: 5rem; } }
    .business-form__item-last {
      width: 12rem; }
      @media (max-width: 991.98px) {
        .business-form__item-last {
          width: 7rem; } }
  .business-form__label {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .business-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .business-form__input {
        font-size: 1.875rem; } }
    .business-form__input:focus, .business-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .business-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .business-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .business-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(14, 79, 79, 0.6); }
      .business-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .business-form__btn:focus, .business-form__btn:active {
      outline: none; }
  .business-form__help {
    display: block;
    color: #76e6e6;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .business-form__help a {
      color: #76e6e6;
      text-decoration: none;
      border-bottom: 1px solid rgba(118, 230, 230, 0.23);
      transition: all 0.2s ease-in-out; }
      .business-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.business-contact {
  background: #d5f7f7;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.business-result {
  padding-bottom: 4rem; }
  .business-result__canvas {
    width: 100%;
    height: 400px; }
    @media (max-width: 991.98px) {
      .business-result__canvas {
        height: 300px; } }
  .business-result-hero {
    background: #26d0d0;
    padding-top: 4rem; }
    .business-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start; }
      .business-result-hero__back:hover {
        text-decoration: none; }
        .business-result-hero__back:hover .business-result-hero__arr {
          fill: #76e6e6; }
        .business-result-hero__back:hover .business-result-hero__link {
          text-decoration: none;
          color: #76e6e6; }
    .business-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .business-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .business-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .business-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .business-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .business-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .business-result__head {
    display: block;
    color: #ef6161;
    font-size: 7.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1.2;
    text-align: center;
    margin: 0;
    padding: 0;
    margin: 2rem auto; }
  .business-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .business-result__sub strong {
      font-weight: 700;
      color: #26d0d0; }
  .business-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .business-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .business-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .business-result__group {
        flex-direction: column; } }
  .business-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #26d0d0;
    background: #fff;
    border: 2px solid #a1eeee;
    box-shadow: 0 0 0 0 rgba(38, 208, 208, 0);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .business-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #26d0d0; }
    .business-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .business-result__btn:hover {
      color: #fff;
      background: #26d0d0;
      border-color: #26d0d0;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(38, 208, 208, 0.6); }
      .business-result__btn:hover svg {
        fill: #76e6e6; }
      .business-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .business-result__btn:focus, .business-result__btn:active {
      outline: none; }
  .business-result-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #d9f8f8;
    border-radius: 0.5rem;
    padding: 2rem 2rem;
    margin-top: 3rem;
    margin-bottom: 2rem; }
    .business-result-item:first-child {
      margin-top: 3rem; }
    .business-result-item:last-child {
      margin-bottom: 0; }
    .business-result-item__head {
      display: block;
      color: #ef6161;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5rem; }
    .business-result-item__sub {
      display: block;
      color: #6c757d;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem; }
    .business-result-item__text {
      display: block;
      color: #000;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6;
      margin: 0;
      padding: 0; }

body.star .header {
  background: #fef6f7; }
  body.star .header__insta {
    border-left-color: #fee7ea; }
    body.star .header__insta .icon {
      color: #f5576c; }
  body.star .header-clients {
    border-right-color: #fee7ea; }
    body.star .header-clients__head, body.star .header-clients__sub {
      color: #f88796; }
  body.star .header-menu {
    border-top-color: #fee7ea;
    transition: all 0.2s ease-in-out; }
  body.star .header__btn {
    border-color: #f5576c;
    color: #f5576c; }
    body.star .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.star .header__btn:hover {
      background: #ac71de;
      color: #fff;
      border-color: #ac71de; }
  @media (max-width: 991.98px) {
    body.star .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(242, 39, 66, 0.6); }
      body.star .header.open .header-logo .light {
        fill: #e9ecef; }
      body.star .header.open .header__insta svg {
        fill: #dee2e6; }
      body.star .header.open .header__insta:hover svg {
        fill: #f5576c; }
      body.star .header.open .header-menu {
        border-top-color: #e9ecef; } }

.star__head {
  display: block;
  color: #f5576c;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.star__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.star__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .star__lead a {
    color: #f5576c;
    border-bottom: 1px solid rgba(245, 87, 108, 0);
    transition: all 0.2s ease-in-out; }
    .star__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.star-hero {
  background: linear-gradient(#fef6f7, #fff); }
  .star-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .star-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .star-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .star-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .star-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .star-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .star-hero__wrap {
        max-width: 1860px; } }
  .star-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .star-hero__head {
        font-size: 3.75rem; } }
  .star-hero__sub {
    display: block;
    color: #f5576c;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .star-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.star-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.star-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #fee7ea;
  border-radius: 0.5rem; }
  .star-div--center {
    align-items: center; }
  .star-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(245, 87, 108, 0.05), rgba(245, 87, 108, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.star-form {
  background: #f5576c;
  background: linear-gradient(45deg, #f5576c, #bf5695);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .star-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .star-form .star__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .star-form .star__sub {
        max-width: 100%; } }
  .star-form .star__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .star-form .star__head {
        font-size: 1.875rem; } }
  .star-form__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .star-form__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .star-form__bot .star-form__item {
      width: 92%;
      margin-right: 0;
      margin-bottom: 1rem; }
  .star-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .star-form__item {
        width: 5rem; } }
    .star-form__item-last {
      width: 12rem;
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .star-form__item-last {
          width: 7rem; } }
  .star-form__label {
    display: block;
    color: #fbb7c0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .star-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .star-form__input {
        font-size: 1.875rem; } }
    .star-form__input:focus, .star-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .star-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .star-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .star-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(169, 10, 31, 0.6); }
      .star-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .star-form__btn:focus, .star-form__btn:active {
      outline: none; }
  .star-form__help {
    display: block;
    color: #fbb7c0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .star-form__help a {
      color: #fbb7c0;
      text-decoration: none;
      border-bottom: 1px solid rgba(251, 183, 192, 0.23);
      transition: all 0.2s ease-in-out; }
      .star-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.star-contact {
  background: white;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.star-result {
  padding-bottom: 4rem; }
  .star-result-hero {
    background: #f5576c;
    padding-top: 4rem; }
    .star-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start; }
      .star-result-hero__back:hover {
        text-decoration: none; }
        .star-result-hero__back:hover .star-result-hero__arr {
          fill: #fbb7c0; }
        .star-result-hero__back:hover .star-result-hero__link {
          text-decoration: none;
          color: #fbb7c0; }
    .star-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .star-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .star-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .star-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .star-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .star-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .star-result__numbers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; }
    .star-result__numbers .letter,
    .star-result__numbers .num {
      display: block;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0; }
    .star-result__numbers .letter {
      color: #adb5bd;
      margin-right: 0.25rem; }
    .star-result__numbers .num {
      color: #f5576c;
      font-weight: 700;
      margin-right: 1rem; }
      .star-result__numbers .num:last-child {
        margin-right: 0; }
  .star-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .star-result__sub strong {
      font-weight: 700;
      color: #f5576c; }
  .star-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .star-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .star-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .star-result__group {
        flex-direction: column; } }
  .star-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #f5576c;
    background: #fff;
    border: 2px solid #fee7ea;
    box-shadow: 0 0 0 0 rgba(245, 87, 108, 0);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .star-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #f5576c; }
    .star-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .star-result__btn:hover {
      color: #fff;
      background: #f5576c;
      border-color: #f5576c;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(245, 87, 108, 0.6); }
      .star-result__btn:hover svg {
        fill: #fbb7c0; }
      .star-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .star-result__btn:focus, .star-result__btn:active {
      outline: none; }
  .star-result__step {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .star-result__step .step {
      background: #fff;
      display: inline-block;
      color: #f5576c;
      border: 2px solid #fee7ea;
      border-radius: 50rem;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 1rem; }
    .star-result__step .star-result__sub {
      font-size: 1.125rem; }
  .star-result__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    padding: 1rem; }
  .star-result .video {
    display: block;
    margin: 0 auto; }

body.days .header {
  background: #c0e4e4; }
  body.days .header__insta {
    border-left-color: #aedcdd; }
    body.days .header__insta .icon {
      color: #409698; }
  body.days .header-clients {
    border-right-color: #aedcdd; }
    body.days .header-clients__head, body.days .header-clients__sub {
      color: #54b5b7; }
  body.days .header-menu {
    border-top-color: #aedcdd;
    transition: all 0.2s ease-in-out; }
  body.days .header__btn {
    border-color: #409698;
    color: #409698; }
    body.days .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.days .header__btn:hover {
      color: #fff;
      border-color: #ac71de;
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.days .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(49, 115, 116, 0.6); }
      body.days .header.open .header-logo .light {
        fill: #e9ecef; }
      body.days .header.open .header__insta svg {
        fill: #dee2e6; }
      body.days .header.open .header__insta:hover svg {
        fill: #409698; }
      body.days .header.open .header-menu {
        border-top-color: #e9ecef; } }

.days__head {
  display: block;
  color: #409698;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.days__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.days__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .days__lead a {
    color: #409698;
    border-bottom: 1px solid rgba(64, 150, 152, 0);
    transition: all 0.2s ease-in-out; }
    .days__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.days-hero {
  background: linear-gradient(#c0e4e4, #fff); }
  .days-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .days-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .days-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .days-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .days-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .days-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .days-hero__wrap {
        max-width: 1860px; } }
  .days-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .days-hero__head {
        font-size: 3.75rem; } }
  .days-hero__sub {
    display: block;
    color: #409698;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .days-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.days-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.days-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #9cd4d5;
  border-radius: 0.5rem; }
  .days-div--center {
    align-items: center; }
  .days-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(64, 150, 152, 0.05), rgba(64, 150, 152, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.days-form {
  background: #409698;
  background: linear-gradient(45deg, #409698, #2a4847);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .days-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .days-form .days__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .days-form .days__sub {
        max-width: 100%; } }
  .days-form .days__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .days-form .days__head {
        font-size: 1.875rem; } }
  .days-form__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .days-form__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .days-form__bot .days-form__item {
      width: 92%;
      margin-right: 0;
      margin-bottom: 1rem; }
  .days-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-dayst;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .days-form__item {
        width: 5rem; } }
    .days-form__item-last {
      width: 12rem;
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .days-form__item-last {
          width: 7rem; } }
  .days-form__label {
    display: block;
    color: #78c4c6;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .days-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .days-form__input {
        font-size: 1.875rem; } }
    .days-form__input:focus, .days-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .days-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .days-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .days-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(19, 44, 44, 0.6); }
      .days-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .days-form__btn:focus, .days-form__btn:active {
      outline: none; }
  .days-form__help {
    display: block;
    color: #78c4c6;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .days-form__help a {
      color: #78c4c6;
      text-decoration: none;
      border-bottom: 1px solid rgba(120, 196, 198, 0.23);
      transition: all 0.2s ease-in-out; }
      .days-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.days-contact {
  background: #c7e7e7;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.days-result {
  padding-bottom: 4rem; }
  .days-result-hero {
    background: #409698;
    padding-top: 4rem; }
    .days-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-dayst; }
      .days-result-hero__back:hover {
        text-decoration: none; }
        .days-result-hero__back:hover .days-result-hero__arr {
          fill: #78c4c6; }
        .days-result-hero__back:hover .days-result-hero__link {
          text-decoration: none;
          color: #78c4c6; }
    .days-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .days-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .days-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .days-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .days-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .days-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .days-result__numbers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; }
    .days-result__numbers .letter,
    .days-result__numbers .num {
      display: block;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0; }
    .days-result__numbers .letter {
      color: #adb5bd;
      margin-right: 0.25rem; }
    .days-result__numbers .num {
      color: #409698;
      font-weight: 700;
      margin-right: 1rem; }
      .days-result__numbers .num:last-child {
        margin-right: 0; }
  .days-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .days-result__sub strong {
      font-weight: 700;
      color: #409698; }
  .days-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .days-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .days-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .days-result__group {
        flex-direction: column; } }
  .days-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #409698;
    background: #fff;
    border: 2px solid #9cd4d5;
    box-shadow: 0 0 0 0 rgba(64, 150, 152, 0);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .days-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #409698; }
    .days-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .days-result__btn:hover {
      color: #fff;
      background: #409698;
      border-color: #409698;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(64, 150, 152, 0.6); }
      .days-result__btn:hover svg {
        fill: #78c4c6; }
      .days-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .days-result__btn:focus, .days-result__btn:active {
      outline: none; }
  .days-result__step {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .days-result__step .step {
      background: #fff;
      display: inline-block;
      color: #409698;
      border: 2px solid #9cd4d5;
      border-radius: 50rem;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 1rem; }
    .days-result__step .days-result__sub {
      font-size: 1.125rem; }
  .days-result__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    padding: 1rem; }
  .days-result .video {
    display: block;
    margin: 0 auto; }

.kids-card {
  display: block;
  background: #fff;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem; }
  .kids-card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e9ecef;
    border-radius: 0.5rem;
    padding: 1.25rem 1rem; }
    .kids-card-title:hover {
      cursor: pointer; }
    .kids-card-title.collapsed .kids-card__img {
      transform: rotate(0); }
  .kids-card__head {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1; }
  .kids-card__img {
    display: block;
    fill: #ced4da;
    width: auto;
    height: 0.75rem;
    transform: rotate(-180deg);
    transition: all 0.2s ease-in-out; }
  .kids-card__lead {
    display: block;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5; }
  .kids-card-content {
    padding: 1.25rem 1rem; }

body.reviews .header {
  background: #f8f9ff; }
  body.reviews .header__insta {
    border-left-color: #edf2ff; }
    body.reviews .header__insta .icon {
      color: #124BFF; }
  body.reviews .header-clients {
    border-right-color: #edf2ff; }
    body.reviews .header-clients__head, body.reviews .header-clients__sub {
      color: #4572ff; }
  body.reviews .header-menu {
    border-top-color: #edf2ff;
    transition: all 0.2s ease-in-out; }
  body.reviews .header__btn {
    border-color: #124BFF;
    color: #124BFF; }
    body.reviews .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.reviews .header__btn:hover {
      color: #fff;
      border-color: #ac71de;
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.reviews .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(242, 39, 66, 0.6); }
      body.reviews .header.open .header-logo .light {
        fill: #e9ecef; }
      body.reviews .header.open .header__insta svg {
        fill: #dee2e6; }
      body.reviews .header.open .header__insta:hover svg {
        fill: #f5576c; }
      body.reviews .header.open .header-menu {
        border-top-color: #e9ecef; } }

.reviews__img {
  display: block;
  height: 3rem;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  user-select: none;
  pointer-events: none; }

.reviews__subhead {
  display: block;
  color: #124BFF;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  padding: 0;
  margin: 0; }
  @media (max-width: 991.98px) {
    .reviews__subhead {
      font-size: 1.125rem;
      margin-bottom: 0.125rem; } }

.reviews__head {
  display: block;
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .reviews__head {
      font-size: 1.5625rem; }
      .reviews__head br {
        display: none; } }

.reviews-hero {
  background: linear-gradient(#f8f9ff, #fff);
  padding-top: 6rem;
  padding-bottom: 3rem; }

.reviews-instagram {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  margin-top: 1rem; }
  .reviews-instagram__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 40%;
    height: auto;
    border-radius: 50rem;
    margin-bottom: 1rem; }
  .reviews-instagram__head {
    display: block;
    color: #000;
    font-size: 1.5625rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.25rem;
    text-align: center; }
  .reviews-instagram__subhead {
    display: block;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
  .reviews-instagram__sub {
    display: block;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
    max-height: 13rem; }
    @media (max-width: 991.98px) {
      .reviews-instagram__sub {
        text-align: justify; } }
  .reviews-instagram__link {
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 0.5rem 1.25rem;
    border: 2px solid #4572ff;
    border-radius: 25rem;
    box-shadow: 0 0.5rem 2rem -0.25rem rgba(0, 53, 222, 0.3);
    transition: all 0.2s ease-in-out; }
    .reviews-instagram__link:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(18, 75, 255, 0.7), rgba(18, 75, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .reviews-instagram__link:hover {
      color: #fff;
      background: #124BFF;
      border-color: #124BFF;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1.5rem 1.5rem -0.75rem rgba(0, 53, 222, 0.7); }
      .reviews-instagram__link:hover:before {
        transform: skewX(-42deg) translateX(460%);
        opacity: 1; }

.reviews-whatsapp {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  margin-top: 1rem; }
  .reviews-whatsapp__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem; }

body.consult .header {
  background: #f8f3fe; }
  body.consult .header-logo svg * {
    transition: all 0.2s ease-in-out; }
  body.consult .header-logo svg .light {
    fill: #e8dbfd; }
  body.consult .header-logo:hover svg .dark {
    fill: #f5576c; }
  body.consult .header-logo:hover svg .light {
    fill: #fee7ea; }
  body.consult .header__insta {
    border-left-color: #f1eafe; }
    body.consult .header__insta svg {
      fill: #a471f7; }
    body.consult .header__insta span {
      color: #8540f5; }
    body.consult .header__insta:hover svg {
      fill: #f5576c; }
    body.consult .header__insta:hover span {
      color: #f5576c; }
  body.consult .header-clients {
    border-right-color: #f1eafe; }
    body.consult .header-clients__head, body.consult .header-clients__sub {
      color: #8540f5; }
  body.consult .header-menu {
    border-top-color: #f1eafe;
    transition: all 0.2s ease-in-out; }
  body.consult .header__btn {
    background: #6610f2; }
    body.consult .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.consult .header__btn:hover {
      background: #f5576c; }
  @media (max-width: 991.98px) {
    body.consult .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(242, 39, 66, 0.6); }
      body.consult .header.open .header-logo .light {
        fill: #e9ecef; }
      body.consult .header.open .header__insta svg {
        fill: #dee2e6; }
      body.consult .header.open .header__insta:hover svg {
        fill: #f5576c; }
      body.consult .header.open .header-menu {
        border-top-color: #e9ecef; } }

.consult-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .consult-legend__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-right: 1rem; }
    .consult-legend__item:before {
      content: '';
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.25rem;
      margin-right: 0.25rem; }
    .consult-legend__item.free:before {
      background: #81cc2f; }
    .consult-legend__item.full:before {
      background: #ed969e; }

.consult__head {
  display: block;
  color: #6610f2;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .consult__head {
      font-size: 1.25rem; } }

.consult__subhead {
  display: block;
  color: #6610f2;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 1.5rem; }

.consult__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1rem; }

.consult__lead {
  display: block;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  padding: 0; }

.consult-hero {
  background: linear-gradient(#f8f3fe, #fff); }
  .consult-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .consult-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .consult-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .consult-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .consult-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .consult-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .consult-hero__wrap {
        max-width: 1860px; } }
  .consult-hero__head {
    display: block;
    color: #000;
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .consult-hero__head {
        font-size: 3.75rem; } }
  .consult-hero__sub {
    display: block;
    color: #6610f2;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem; }
  .consult-hero-slider {
    max-width: 100%; }
    .consult-hero-slider__item {
      user-select: none;
      pointer-events: none;
      display: block; }

.consult-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.consult-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #c3a1fa;
  border-radius: 0.5rem; }
  .consult-div--center {
    align-items: center; }
  .consult-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(102, 16, 242, 0.05), rgba(102, 16, 242, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.consult-form {
  background: #FFBB19;
  background: linear-gradient(45deg, #6610f2, #bf5695);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .consult-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .consult-form .business__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 2rem; }
    @media (max-width: 991.98px) {
      .consult-form .business__head {
        font-size: 1.875rem; } }
  .consult-form__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 2rem; }
  .consult-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .consult-form__item {
        width: 5rem; } }
    .consult-form__item-last {
      width: 12rem; }
      @media (max-width: 991.98px) {
        .consult-form__item-last {
          width: 7rem; } }
  .consult-form__label {
    display: block;
    color: #ffd97f;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .consult-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .consult-form__input {
        font-size: 1.875rem; } }
    .consult-form__input:focus, .consult-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .consult-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .consult-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .consult-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(127, 89, 0, 0.6); }
      .consult-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .consult-form__btn:focus, .consult-form__btn:active {
      outline: none; }
  .consult-form__help {
    display: block;
    color: #ffd97f;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .consult-form__help a {
      color: #ffd97f;
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 217, 127, 0.23);
      transition: all 0.2s ease-in-out; }
      .consult-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.consult-contact {
  background: #fffaef;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.payment__head {
  display: block;
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem; }

.payment__top {
  display: block;
  background: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 4rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }

.payment__bot {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 0rem;
  margin-bottom: 4rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: #f8f9fa; }

.payment-logo {
  display: block;
  width: 10rem;
  height: auto;
  margin: 0 auto; }
  @media (max-width: 991.98px) {
    .payment-logo {
      width: 8rem; } }
  @media (max-width: 1199.98px) {
    .payment-logo {
      margin-left: auto;
      margin-right: 0; } }
  .payment-logo .dark {
    fill: #fff; }
  .payment-logo .light {
    fill: #f88796; }

.payment-hero {
  background: #f5576c;
  padding-top: 4rem; }
  .payment-hero__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
  .payment-hero__back {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start; }
    .payment-hero__back:hover {
      text-decoration: none; }
      .payment-hero__back:hover .payment-hero__arr {
        fill: rgba(255, 255, 255, 0.8); }
      .payment-hero__back:hover .payment-hero__link {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.8); }
  .payment-hero__arr {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
    fill: #fff;
    transition: all 0.2s ease-in-out; }
  .payment-hero__link {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out; }
  .payment-hero__add {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end; }
  .payment-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 2rem;
    width: auto;
    margin: 0;
    padding: 0; }
  .payment-hero__head {
    display: block;
    color: #fbb7c0;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-right: 1rem; }

.payment-method {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  position: relative;
  user-select: none; }
  .payment-method.disabled {
    user-select: none;
    pointer-events: none; }
    .payment-method.disabled .payment-method__div {
      background: #e9ecef; }
  .payment-method:hover {
    cursor: pointer; }
    .payment-method:hover .payment-method__div {
      border: 2px solid #ced4da; }
  .payment-method__input {
    position: absolute;
    opacity: 0;
    visibility: hidden; }
    .payment-method__input:checked ~ .payment-method__div {
      border: 2px solid #f5576c; }
  .payment-method__div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #e9ecef;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out; }
  .payment-method__img {
    user-select: none;
    pointer-events: none;
    display: block;
    z-index: 999;
    height: 2rem;
    width: auto; }

.payment-group {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-end; }
  .payment-group.has-error .payment-group__input {
    border-color: #dc3545; }
  .payment-group__label {
    display: block;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .payment-group__input {
    display: block;
    width: 100%;
    background: #fff;
    border: 2px solid #e9ecef;
    border-radius: 0.5rem;
    padding: 1rem 0.75rem;
    margin: 0;
    margin-bottom: 2rem;
    transition: border linear .1s; }
    .payment-group__input:focus, .payment-group__input:active {
      outline: none;
      border-color: #f5576c; }
  .payment-group__error {
    display: block;
    color: #dc3545;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-top: -0.5rem; }

.payment__btn {
  display: block;
  position: relative;
  overflow: hidden;
  color: #fff;
  background: #f5576c;
  border: none;
  box-shadow: 0 1rem 1rem -0.5rem rgba(245, 87, 108, 0.3);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 1rem 1.5rem;
  border-radius: 25rem;
  margin-bottom: 9rem;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out; }
  .payment__btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
    width: 3rem;
    height: 100%;
    transform: skewX(-42deg) translateX(-10%);
    z-index: 1;
    will-change: transform;
    opacity: 0;
    transition: all 0.3s ease; }
  .payment__btn:hover {
    color: #fff;
    text-decoration: none;
    transform: translateY(-10px);
    box-shadow: 0 2.5rem 2rem -1rem rgba(245, 87, 108, 0.6); }
    .payment__btn:hover:before {
      transform: skewX(-42deg) translateX(510%);
      opacity: 1; }
  .payment__btn:focus, .payment__btn:active {
    outline: none; }

.payment-footer {
  margin-bottom: 2rem; }
  .payment-footer__img {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 2rem;
    width: auto;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
  .payment-footer__head {
    display: block;
    color: #212529;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .payment-footer__sub {
    display: block;
    color: #adb5bd;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0; }

body.mandala .header {
  background: #defaf2; }
  body.mandala .header-logo svg * {
    transition: all 0.2s ease-in-out; }
  body.mandala .header-logo svg .light {
    fill: #9cf0d8; }
  body.mandala .header-logo:hover svg .dark {
    fill: #ac71de; }
  body.mandala .header-logo:hover svg .light {
    fill: #9cf0d8; }
  body.mandala .header__insta {
    border-left-color: #b2f3e1; }
    body.mandala .header__insta svg {
      fill: #70e9c7; }
    body.mandala .header__insta span {
      color: #43e3b5; }
    body.mandala .header__insta:hover svg {
      fill: #ac71de; }
    body.mandala .header__insta:hover span {
      color: #ac71de; }
  body.mandala .header-clients {
    border-right-color: #b2f3e1; }
    body.mandala .header-clients__head, body.mandala .header-clients__sub {
      color: #43e3b5; }
  body.mandala .header-menu {
    border-top-color: #b2f3e1;
    transition: all 0.2s ease-in-out; }
  body.mandala .header__btn {
    background: #20d3a0; }
    body.mandala .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.mandala .header__btn:hover {
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.mandala .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(25, 167, 126, 0.6); }
      body.mandala .header.open .header-logo .light {
        fill: #e9ecef; }
      body.mandala .header.open .header__insta svg {
        fill: #dee2e6; }
      body.mandala .header.open .header__insta:hover svg {
        fill: #20d3a0; }
      body.mandala .header.open .header-menu {
        border-top-color: #e9ecef; } }

.mandala__head {
  display: block;
  color: #20d3a0;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.mandala__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.mandala__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .mandala__lead a {
    color: #20d3a0;
    border-bottom: 1px solid rgba(32, 211, 160, 0);
    transition: all 0.2s ease-in-out; }
    .mandala__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.mandala-hero {
  background: linear-gradient(#defaf2, #fff); }
  .mandala-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .mandala-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .mandala-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .mandala-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .mandala-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .mandala-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .mandala-hero__wrap {
        max-width: 1860px; } }
  .mandala-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .mandala-hero__head {
        font-size: 3.75rem; } }
  .mandala-hero__sub {
    display: block;
    color: #20d3a0;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .mandala-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.mandala-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mandala-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #9cf0d8;
  border-radius: 0.5rem; }
  .mandala-div--center {
    align-items: center; }
  .mandala-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(32, 211, 160, 0.05), rgba(32, 211, 160, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.mandala-form {
  background: #20d3a0;
  background: linear-gradient(45deg, #20d3a0, #2fa3dd);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .mandala-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .mandala-form .mandala__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .mandala-form .mandala__sub {
        max-width: 100%; } }
  .mandala-form .mandala__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .mandala-form .mandala__head {
        font-size: 1.875rem; } }
  .mandala-form__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .mandala-form__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .mandala-form__bot .mandala-form__item {
      width: 92%;
      margin-right: 0;
      margin-bottom: 1rem; }
  .mandala-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-mandalat;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .mandala-form__item {
        width: 5rem; } }
    .mandala-form__item-last {
      width: 12rem;
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .mandala-form__item-last {
          width: 7rem; } }
  .mandala-form__label {
    display: block;
    color: #70e9c7;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .mandala-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .mandala-form__input {
        font-size: 1.875rem; } }
    .mandala-form__input:focus, .mandala-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .mandala-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .mandala-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .mandala-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(12, 78, 59, 0.6); }
      .mandala-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .mandala-form__btn:focus, .mandala-form__btn:active {
      outline: none; }
  .mandala-form__help {
    display: block;
    color: #70e9c7;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .mandala-form__help a {
      color: #70e9c7;
      text-decoration: none;
      border-bottom: 1px solid rgba(112, 233, 199, 0.23);
      transition: all 0.2s ease-in-out; }
      .mandala-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.mandala-contact {
  background: #d1f8ed;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.mandala-result {
  /* Hide the browser's default radio button */
  /* When the radio button is checked, add a blue background */
  padding-bottom: 4rem; }
  .mandala-result .cont {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .mandala-result .cont input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .mandala-result .checkmark {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border: 4px solid transparent; }
  .mandala-result .cont input:checked ~ .checkmark {
    border-color: #20d3a0; }
  .mandala-result__mandala {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 2rem; }
    .mandala-result__mandala svg {
      max-width: 100%; }
    .mandala-result__mandala img {
      max-width: 100%; }
  .mandala-result-hero {
    background: #20d3a0;
    padding-top: 4rem; }
    .mandala-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-mandalat; }
      .mandala-result-hero__back:hover {
        text-decoration: none; }
        .mandala-result-hero__back:hover .mandala-result-hero__arr {
          fill: #70e9c7; }
        .mandala-result-hero__back:hover .mandala-result-hero__link {
          text-decoration: none;
          color: #70e9c7; }
    .mandala-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .mandala-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .mandala-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .mandala-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .mandala-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .mandala-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .mandala-result__numbers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; }
    .mandala-result__numbers .letter,
    .mandala-result__numbers .num {
      display: block;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0; }
    .mandala-result__numbers .letter {
      color: #adb5bd;
      margin-right: 0.25rem; }
    .mandala-result__numbers .num {
      color: #20d3a0;
      font-weight: 700;
      margin-right: 1rem; }
      .mandala-result__numbers .num:last-child {
        margin-right: 0; }
  .mandala-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .mandala-result__sub strong {
      font-weight: 700;
      color: #20d3a0; }
  .mandala-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .mandala-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .mandala-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .mandala-result__group {
        flex-direction: column; } }
  .mandala-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #20d3a0;
    background: #fff;
    border: 2px solid #9cf0d8;
    box-shadow: 0 0 0 0 rgba(32, 211, 160, 0);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .mandala-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #20d3a0; }
    .mandala-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .mandala-result__btn:hover {
      color: #fff;
      background: #20d3a0;
      border-color: #20d3a0;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(32, 211, 160, 0.6); }
      .mandala-result__btn:hover svg {
        fill: #70e9c7; }
      .mandala-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .mandala-result__btn:focus, .mandala-result__btn:active {
      outline: none; }
  .mandala-result__step {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .mandala-result__step .step {
      background: #fff;
      display: inline-block;
      color: #20d3a0;
      border: 2px solid #9cf0d8;
      border-radius: 50rem;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 1rem; }
    .mandala-result__step .mandala-result__sub {
      font-size: 1.125rem; }
  .mandala-result__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    padding: 1rem; }
  .mandala-result .video {
    display: block;
    margin: 0 auto; }

body.sovm .header {
  background: #fae1ea; }
  body.sovm .header__insta {
    border-left-color: #f7cbda; }
    body.sovm .header__insta .icon {
      color: #ec89ab; }
  body.sovm .header-clients {
    border-right-color: #f7cbda; }
    body.sovm .header-clients__head, body.sovm .header-clients__sub {
      color: #e55d8c; }
  body.sovm .header-menu {
    border-top-color: #f7cbda;
    transition: all 0.2s ease-in-out; }
  body.sovm .header__btn {
    border-color: #de316d;
    color: #de316d; }
    body.sovm .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.sovm .header__btn:hover {
      color: #fff;
      border-color: #ac71de;
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.sovm .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(190, 30, 86, 0.6); }
      body.sovm .header.open .header-logo .light {
        fill: #e9ecef; }
      body.sovm .header.open .header__insta svg {
        fill: #dee2e6; }
      body.sovm .header.open .header__insta:hover svg {
        fill: #de316d; }
      body.sovm .header.open .header-menu {
        border-top-color: #e9ecef; } }

.sovm__head {
  display: block;
  color: #de316d;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.sovm__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.sovm__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .sovm__lead a {
    color: #de316d;
    border-bottom: 1px solid rgba(222, 49, 109, 0);
    transition: all 0.2s ease-in-out; }
    .sovm__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.sovm-tabs {
  background: #fff;
  border-radius: 50rem;
  border: none;
  padding: 0.35rem;
  margin-top: 1rem; }
  .sovm-tabs .nav-item {
    margin-top: 0;
    margin-bottom: 0; }
  .sovm-tabs .nav-link {
    color: #de316d;
    background-color: transparent;
    border: none;
    border-radius: 50rem; }
    .sovm-tabs .nav-link.active {
      color: #fff;
      background-color: #de316d; }

.sovm-hero {
  background: linear-gradient(#fae1ea, #fff); }
  .sovm-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .sovm-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .sovm-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .sovm-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .sovm-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .sovm-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .sovm-hero__wrap {
        max-width: 1860px; } }
  .sovm-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .sovm-hero__head {
        font-size: 3.75rem; } }
  .sovm-hero__sub {
    display: block;
    color: #de316d;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .sovm-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.sovm-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.sovm-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #f3b5ca;
  border-radius: 0.5rem; }
  .sovm-div--center {
    align-items: center; }
  .sovm-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(222, 49, 109, 0.05), rgba(222, 49, 109, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.sovm-form {
  background: #de316d;
  background: linear-gradient(45deg, #de316d, #2a4847);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .sovm-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .sovm-form .sovm__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .sovm-form .sovm__sub {
        max-width: 100%; } }
  .sovm-form .sovm__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .sovm-form .sovm__head {
        font-size: 1.875rem; } }
  .sovm-form__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .sovm-form__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .sovm-form__bot .sovm-form__item {
      width: 92%;
      margin-right: 0;
      margin-bottom: 1rem; }
  .sovm-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-sovmt;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .sovm-form__item {
        width: 5rem; } }
    .sovm-form__item-last {
      width: 12rem;
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .sovm-form__item-last {
          width: 7rem; } }
  .sovm-form__label {
    display: block;
    color: #ec89ab;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .sovm-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .sovm-form__input {
        font-size: 1.875rem; } }
    .sovm-form__input:focus, .sovm-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .sovm-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .sovm-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .sovm-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(102, 16, 46, 0.6); }
      .sovm-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .sovm-form__btn:focus, .sovm-form__btn:active {
      outline: none; }
  .sovm-form__help {
    display: block;
    color: #ec89ab;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .sovm-form__help a {
      color: #ec89ab;
      text-decoration: none;
      border-bottom: 1px solid rgba(236, 137, 171, 0.23);
      transition: all 0.2s ease-in-out; }
      .sovm-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.sovm-contact {
  background: #fceaf0;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.sovm-result {
  padding-bottom: 4rem; }
  .sovm-result-hero {
    background: #de316d;
    padding-top: 4rem; }
    .sovm-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-sovmt; }
      .sovm-result-hero__back:hover {
        text-decoration: none; }
        .sovm-result-hero__back:hover .sovm-result-hero__arr {
          fill: #ec89ab; }
        .sovm-result-hero__back:hover .sovm-result-hero__link {
          text-decoration: none;
          color: #ec89ab; }
    .sovm-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .sovm-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .sovm-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .sovm-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .sovm-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .sovm-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .sovm-result__numbers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; }
    .sovm-result__numbers .letter,
    .sovm-result__numbers .num {
      display: block;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0; }
    .sovm-result__numbers .letter {
      color: #adb5bd;
      margin-right: 0.25rem; }
    .sovm-result__numbers .num {
      color: #de316d;
      font-weight: 700;
      margin-right: 1rem; }
      .sovm-result__numbers .num:last-child {
        margin-right: 0; }
  .sovm-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .sovm-result__sub strong {
      font-weight: 700;
      color: #de316d; }
  .sovm-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .sovm-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .sovm-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .sovm-result__group {
        flex-direction: column; } }
  .sovm-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #de316d;
    background: #fff;
    border: 2px solid #f3b5ca;
    box-shadow: 0 0 0 0 rgba(222, 49, 109, 0);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .sovm-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #de316d; }
    .sovm-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .sovm-result__btn:hover {
      color: #fff;
      background: #de316d;
      border-color: #de316d;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(222, 49, 109, 0.6); }
      .sovm-result__btn:hover svg {
        fill: #ec89ab; }
      .sovm-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .sovm-result__btn:focus, .sovm-result__btn:active {
      outline: none; }
  .sovm-result__step {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .sovm-result__step .step {
      background: #fff;
      display: inline-block;
      color: #de316d;
      border: 2px solid #f3b5ca;
      border-radius: 50rem;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 1rem; }
    .sovm-result__step .sovm-result__sub {
      font-size: 1.125rem; }
  .sovm-result__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    padding: 1rem; }
  .sovm-result .video {
    display: block;
    margin: 0 auto; }

body.risk .header {
  background: #e8f5fd; }
  body.risk .header__insta {
    border-left-color: #a1d8f7; }
    body.risk .header__insta .icon {
      color: #5abbf2; }
  body.risk .header-clients {
    border-right-color: #a1d8f7; }
    body.risk .header-clients__head, body.risk .header-clients__sub {
      color: #2ba7ee; }
  body.risk .header-menu {
    border-top-color: #a1d8f7;
    transition: all 0.2s ease-in-out; }
  body.risk .header__btn {
    border-color: #118ed5;
    color: #118ed5; }
    body.risk .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.risk .header__btn:hover {
      color: #fff;
      border-color: #ac71de;
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.risk .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(13, 111, 166, 0.6); }
      body.risk .header.open .header-logo .light {
        fill: #e9ecef; }
      body.risk .header.open .header__insta svg {
        fill: #dee2e6; }
      body.risk .header.open .header__insta:hover svg {
        fill: #118ed5; }
      body.risk .header.open .header-menu {
        border-top-color: #e9ecef; } }

.risk__head {
  display: block;
  color: #118ed5;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.risk__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.risk__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .risk__lead a {
    color: #118ed5;
    border-bottom: 1px solid rgba(17, 142, 213, 0);
    transition: all 0.2s ease-in-out; }
    .risk__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.risk-tabs {
  background: #fff;
  border-radius: 50rem;
  border: none;
  padding: 0.35rem;
  margin-top: 1rem; }
  .risk-tabs .nav-item {
    margin-top: 0;
    margin-bottom: 0; }
  .risk-tabs .nav-link {
    color: #118ed5;
    background-color: transparent;
    border: none;
    border-radius: 50rem; }
    .risk-tabs .nav-link.active {
      color: #fff;
      background-color: #118ed5; }

.risk-hero {
  background: linear-gradient(#e8f5fd, #fff); }
  .risk-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .risk-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .risk-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .risk-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .risk-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .risk-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .risk-hero__wrap {
        max-width: 1860px; } }
  .risk-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .risk-hero__head {
        font-size: 3.75rem; } }
  .risk-hero__sub {
    display: block;
    color: #118ed5;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .risk-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.risk-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.risk-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #89cef6;
  border-radius: 0.5rem; }
  .risk-div--center {
    align-items: center; }
  .risk-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(17, 142, 213, 0.05), rgba(17, 142, 213, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.risk-form {
  background: #118ed5;
  background: linear-gradient(45deg, #118ed5, #2a4847);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .risk-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .risk-form .risk__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .risk-form .risk__sub {
        max-width: 100%; } }
  .risk-form .risk__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .risk-form .risk__head {
        font-size: 1.875rem; } }
  .risk-form__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .risk-form__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .risk-form__bot .risk-form__item {
      width: 92%;
      margin-right: 0;
      margin-bottom: 1rem; }
  .risk-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-riskt;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .risk-form__item {
        width: 5rem; } }
    .risk-form__item-last {
      width: 12rem;
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .risk-form__item-last {
          width: 7rem; } }
  .risk-form__label {
    display: block;
    color: #5abbf2;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .risk-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .risk-form__input {
        font-size: 1.875rem; } }
    .risk-form__input:focus, .risk-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .risk-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .risk-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .risk-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(6, 48, 71, 0.6); }
      .risk-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .risk-form__btn:focus, .risk-form__btn:active {
      outline: none; }
  .risk-form__help {
    display: block;
    color: #5abbf2;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .risk-form__help a {
      color: #5abbf2;
      text-decoration: none;
      border-bottom: 1px solid rgba(90, 187, 242, 0.23);
      transition: all 0.2s ease-in-out; }
      .risk-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.risk-contact {
  background: #c2e6fa;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.risk-result {
  padding-bottom: 4rem; }
  .risk-result-hero {
    background: #118ed5;
    padding-top: 4rem; }
    .risk-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-riskt; }
      .risk-result-hero__back:hover {
        text-decoration: none; }
        .risk-result-hero__back:hover .risk-result-hero__arr {
          fill: #5abbf2; }
        .risk-result-hero__back:hover .risk-result-hero__link {
          text-decoration: none;
          color: #5abbf2; }
    .risk-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .risk-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .risk-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .risk-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .risk-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .risk-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .risk-result__numbers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; }
    .risk-result__numbers .letter,
    .risk-result__numbers .num {
      display: block;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0; }
    .risk-result__numbers .letter {
      color: #adb5bd;
      margin-right: 0.25rem; }
    .risk-result__numbers .num {
      color: #118ed5;
      font-weight: 700;
      margin-right: 1rem; }
      .risk-result__numbers .num:last-child {
        margin-right: 0; }
  .risk-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .risk-result__sub strong {
      font-weight: 700;
      color: #118ed5; }
  .risk-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .risk-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .risk-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .risk-result__group {
        flex-direction: column; } }
  .risk-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #118ed5;
    background: #fff;
    border: 2px solid #89cef6;
    box-shadow: 0 0 0 0 rgba(17, 142, 213, 0);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .risk-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #118ed5; }
    .risk-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .risk-result__btn:hover {
      color: #fff;
      background: #118ed5;
      border-color: #118ed5;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(17, 142, 213, 0.6); }
      .risk-result__btn:hover svg {
        fill: #5abbf2; }
      .risk-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .risk-result__btn:focus, .risk-result__btn:active {
      outline: none; }
  .risk-result__step {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .risk-result__step .step {
      background: #fff;
      display: inline-block;
      color: #118ed5;
      border: 2px solid #89cef6;
      border-radius: 50rem;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 1rem; }
    .risk-result__step .risk-result__sub {
      font-size: 1.125rem; }
  .risk-result__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    padding: 1rem; }
  .risk-result .video {
    display: block;
    margin: 0 auto; }

.training .hero {
  background: linear-gradient(90deg, #f8edeb, rgba(240, 113, 103, 0.25));
  border-bottom: 1px solid #f8edeb;
  position: relative;
  width: 100%; }
  @media (max-width: 1199.98px) {
    .training .hero {
      margin-top: 1rem; } }
  .training .hero .im {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80%;
    width: auto;
    user-select: none;
    pointer-events: none; }
    @media (max-width: 1199.98px) {
      .training .hero .im {
        position: relative;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto; } }
  .training .hero .headline {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    position: relative;
    z-index: 2; }
    @media (max-width: 991.98px) {
      .training .hero .headline {
        padding-bottom: 2rem;
        padding-top: 4rem; } }
    .training .hero .headline .sub {
      display: block;
      color: #6d597a;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1;
      margin: 0;
      padding: 0.5rem 1rem;
      border-radius: 50rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      border: 1px solid rgba(109, 89, 122, 0.25);
      margin-bottom: 1rem; }
    .training .hero .headline .head {
      display: block;
      color: #6d597a;
      font-size: 5rem;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 1px;
      background: linear-gradient(135deg, #6d597a, #f07167);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem; }
      @media (max-width: 1365.98px) {
        .training .hero .headline .head {
          font-size: 5vw; } }
      @media (max-width: 991.98px) {
        .training .hero .headline .head {
          font-size: 2.5rem; } }
    .training .hero .headline .text {
      display: block;
      color: #af6570;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      margin: 0;
      padding: 0; }

.training .about {
  padding-top: 6rem;
  padding-bottom: 6rem; }
  @media (max-width: 991.98px) {
    .training .about {
      padding-top: 2rem;
      padding-bottom: 1rem; } }
  .training .about .item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    border: 1px solid rgba(240, 113, 103, 0.125);
    box-shadow: 0 0.5rem 2rem -0.5rem rgba(109, 89, 122, 0.25);
    border-radius: 0.5rem;
    padding: 1.75rem 1.5rem; }
    @media (max-width: 991.98px) {
      .training .about .item {
        margin-bottom: 2rem; } }
    .training .about .item .head {
      display: block;
      color: #735d78;
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.75rem;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5rem; }
    .training .about .item .text {
      display: block;
      color: rgba(115, 93, 120, 0.75);
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.375rem;
      margin: 0;
      padding: 0; }

.training .todo {
  padding-top: 4rem;
  padding-bottom: 4rem; }
  @media (max-width: 991.98px) {
    .training .todo {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  .training .todo-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .training .todo-header .head {
      display: inline-block;
      color: #6d597a;
      font-size: 3rem;
      font-weight: 500;
      line-height: 3.25rem;
      text-align: center;
      margin-bottom: 4rem;
      position: relative;
      z-index: 1; }
      @media (max-width: 991.98px) {
        .training .todo-header .head {
          font-size: 1.75rem;
          line-height: 2rem;
          margin-bottom: 2rem; } }
      .training .todo-header .head:after {
        content: '';
        display: inline-block;
        height: 0.5rem;
        width: 100%;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        background: rgba(240, 113, 103, 0.125); }
        @media (max-width: 991.98px) {
          .training .todo-header .head:after {
            height: 0.25rem; } }
  .training .todo .item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    background: #f8edeb;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 2rem rgba(248, 237, 235, 0.5);
    padding: 2.5rem 1.5rem; }
    @media (max-width: 991.98px) {
      .training .todo .item {
        margin-bottom: 2rem; } }
    .training .todo .item .img {
      display: block;
      height: 7rem;
      width: auto;
      user-select: none;
      pointer-events: none;
      margin-bottom: 1rem;
      position: relative;
      padding: 0.5rem;
      background: #fff;
      border-radius: 50rem; }
      @media (max-width: 991.98px) {
        .training .todo .item .img {
          height: 5rem; } }
      .training .todo .item .img .icon {
        display: block;
        height: 100%;
        width: auto; }
    .training .todo .item .head {
      display: block;
      color: #6d597a;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5rem; }
    .training .todo .item .sub {
      display: block;
      color: rgba(109, 89, 122, 0.75);
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin: 0;
      padding: 0; }
  .training .todo .timeline {
    list-style-type: none;
    position: relative; }
    .training .todo .timeline:before {
      content: '';
      background: #f8edeb;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400; }
      @media (max-width: 991.98px) {
        .training .todo .timeline:before {
          left: 0.5rem; } }
    .training .todo .timeline-item {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      background: #fff;
      border-radius: 0.5rem;
      margin-right: 1rem;
      margin-bottom: 4rem;
      padding-left: 1.5rem; }
      @media (max-width: 991.98px) {
        .training .todo .timeline-item {
          padding-left: 0; } }
      .training .todo .timeline-item:before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #6d6875;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 400; }
        @media (max-width: 991.98px) {
          .training .todo .timeline-item:before {
            left: 0rem; } }
      .training .todo .timeline-item .head {
        display: block;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
        margin-bottom: 2rem; }
    .training .todo .timeline-list {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 1rem; }
      .training .todo .timeline-list li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        margin: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #000;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.25rem; }
        .training .todo .timeline-list li .check {
          display: block;
          height: 1rem;
          width: auto;
          user-select: none;
          pointer-events: none;
          margin-right: 0.5rem; }
    .training .todo .timeline .gift {
      display: flex;
      flex-direction: column;
      align-items: baseline; }
      .training .todo .timeline .gift-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
        background: #6d597a;
        background: linear-gradient(-45deg, #6d597a, #f07167);
        padding: 0.25rem 0.75rem;
        border-radius: 50rem;
        margin-bottom: 0.375rem; }
        @media (max-width: 991.98px) {
          .training .todo .timeline .gift-item {
            border-radius: 0.25rem; } }
        .training .todo .timeline .gift-item:last-child {
          margin-bottom: 0; }
      .training .todo .timeline .gift-icon {
        display: block;
        height: 1rem;
        width: auto;
        user-select: none;
        pointer-events: none;
        margin-right: 0.375rem;
        opacity: 0.5; }

.training-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  background: linear-gradient(135deg, rgba(109, 89, 122, 0.125), rgba(240, 113, 103, 0.25)); }
  .training-form .info {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%; }
    .training-form .info .price {
      display: block;
      color: #6d597a;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.5rem;
      margin: 0;
      padding: 0;
      margin-bottom: 1.25rem; }
      .training-form .info .price strong {
        color: #fff;
        background: #f07167;
        border-radius: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem; }
    .training-form .info .text {
      display: inline-block;
      color: #6d597a;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.375rem;
      background: rgba(109, 89, 122, 0.125);
      border-radius: 0.25rem;
      padding: 1rem;
      margin-bottom: 2rem; }
  .training-form .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem; }
    @media (max-width: 991.98px) {
      .training-form .items {
        flex-direction: column; } }
    .training-form .items .training-form__item {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      flex: 1; }
      @media (max-width: 991.98px) {
        .training-form .items .training-form__item {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 1rem;
          width: 100%; } }
      .training-form .items .training-form__item:first-child {
        margin-left: 0; }
      .training-form .items .training-form__item:last-child {
        margin-right: 0; }
      .training-form .items .training-form__item label {
        display: block;
        color: #6d597a;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1;
        margin: 0;
        padding: 0;
        margin-bottom: 0.5rem; }
      .training-form .items .training-form__item input {
        display: block;
        position: relative;
        width: 100%;
        color: #000;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1;
        background: #fff;
        border: 1px solid rgba(109, 89, 122, 0.25);
        border-radius: 0.25rem;
        margin: 0;
        padding: 0;
        padding: 1rem 0.75rem;
        transition: all 0.2s ease-in-out; }
        .training-form .items .training-form__item input:hover {
          border-color: #6d597a; }
        .training-form .items .training-form__item input:focus, .training-form .items .training-form__item input:active {
          outline: none;
          border-color: #6d597a;
          box-shadow: 0 0 0 3px rgba(109, 89, 122, 0.125); }
  .training-form .btn {
    color: #fff;
    background: #6d597a;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0;
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    margin-bottom: 2rem; }
    @media (max-width: 991.98px) {
      .training-form .btn {
        width: 100%; } }
    .training-form .btn:hover {
      cursor: pointer;
      background: rgba(109, 89, 122, 0.75);
      text-decoration: none;
      transform: translateY(-3px);
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(83, 67, 93, 0.6); }
  .training-form .help {
    color: rgba(109, 89, 122, 0.75);
    margin: 0;
    padding: 0; }
    .training-form .help a {
      color: #f07167; }

.tg {
  overflow: hidden;
  max-width: 100%; }
  .tg-hero {
    margin-top: 4rem;
    margin-bottom: 2rem; }
    .tg-hero__img {
      display: block;
      height: 8rem;
      width: auto;
      user-select: none;
      pointer-events: none;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .tg-hero__img {
          height: 4rem; } }
  .tg-home {
    position: relative;
    z-index: 1; }
    .tg-home .container {
      position: relative;
      z-index: 1; }
    .tg-home__img {
      display: block;
      margin: 0 auto;
      max-width: 70%;
      user-select: none;
      pointer-events: none; }
      @media (max-width: 991.98px) {
        .tg-home__img {
          max-width: 130%;
          margin-left: -20%; } }
    .tg-home__bg1 {
      display: block;
      margin: 0 auto;
      max-width: 70%;
      user-select: none;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0; }
      @media (max-width: 991.98px) {
        .tg-home__bg1 {
          max-width: 170%;
          margin-left: 0; } }
      @media (max-width: 991.98px) {
        .tg-home__bg1.bg2 {
          max-width: 170%;
          margin-left: -60%; } }
      .tg-home__bg1.bg3 {
        display: none; }
        @media (max-width: 991.98px) {
          .tg-home__bg1.bg3 {
            display: block;
            max-width: 170%;
            margin-left: -100%; } }
    .tg-home:after {
      content: '';
      background: #f0c3b8;
      background: linear-gradient(78deg, #f0c3b8, #f5b1b1);
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80%; }
  .tg-prem {
    background: #fff;
    position: relative;
    z-index: 3;
    padding-top: 8rem;
    margin-bottom: 2rem; }
    @media (max-width: 991.98px) {
      .tg-prem {
        padding-top: 4rem;
        margin-left: -1rem;
        margin-right: -1rem;
        width: calc(100% + 2rem); } }
    .tg-prem-list {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
      background: rgba(240, 195, 184, 0.5);
      background: linear-gradient(78deg, rgba(240, 195, 184, 0.5), rgba(245, 177, 177, 0.5));
      border-radius: 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 3rem 2rem; }
      @media (max-width: 991.98px) {
        .tg-prem-list {
          padding: 2rem 1rem;
          border-radius: 0; } }
      .tg-prem-list__head {
        display: block;
        color: #362b2b;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        line-height: 1.825rem; }
        @media (max-width: 991.98px) {
          .tg-prem-list__head {
            font-size: 1.5rem;
            line-height: 1.25rem;
            margin-bottom: 2rem; } }
    .tg-prem-item {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 1rem;
      background: #fff;
      border-radius: 0.5rem;
      padding: 1rem;
      padding-left: 3rem;
      position: relative;
      z-index: 1;
      box-shadow: 0 0.25rem 0.5rem rgba(48, 37, 37, 0.125); }
      .tg-prem-item__icon {
        display: block;
        font-size: 1.25rem;
        color: #ff6f20;
        position: absolute;
        z-index: 1;
        left: 1rem;
        top: 1.125rem; }
        @media (max-width: 991.98px) {
          .tg-prem-item__icon {
            top: 50%;
            transform: translateY(-50%); } }
      .tg-prem-item__text {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: #362b2b; }
    .tg-prem-footer {
      margin-top: 1rem;
      opacity: 0.5; }
    .tg-prem-price {
      background: rgba(240, 195, 184, 0.5);
      background: linear-gradient(78deg, rgba(240, 195, 184, 0.5), rgba(245, 177, 177, 0.5));
      border-radius: 1rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 3rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991.98px) {
        .tg-prem-price {
          margin-left: -1rem;
          margin-right: -1rem;
          width: calc(100% + 2rem); } }
      .tg-prem-price-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: #fff;
        border-radius: 1rem;
        padding: 2rem 1rem; }
      .tg-prem-price__prehead {
        display: block;
        color: #ff9f2e;
        font-size: 0.825rem;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        padding: 0; }
      .tg-prem-price__head {
        display: block;
        color: #362b2b;
        font-size: 2rem;
        line-height: 1;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-bottom: 2rem;
        text-align: center; }
        @media (max-width: 991.98px) {
          .tg-prem-price__head {
            font-size: 1.5rem; } }
      .tg-prem-price__price {
        display: block;
        color: #362b2b;
        font-size: 5rem;
        line-height: 1;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-bottom: 0.5rem;
        background: linear-gradient(76deg, #ff9f2e, #ff4817);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
        @media (max-width: 991.98px) {
          .tg-prem-price__price {
            font-size: 3rem; } }
      .tg-prem-price-help {
        display: flex;
        align-items: center;
        margin: 2rem auto 0 auto;
        border: 1px solid rgba(255, 72, 23, 0.375);
        border-radius: 0.5rem;
        padding: 1rem;
        width: 80%; }
        @media (max-width: 991.98px) {
          .tg-prem-price-help {
            width: 100%; } }
        .tg-prem-price-help__icon {
          color: #ff4817;
          font-size: 2rem;
          opacity: 0.5;
          margin-right: 0.75rem; }
        .tg-prem-price-help__text {
          color: #ff4817;
          font-size: 1rem;
          line-height: 1.125rem;
          font-weight: 400; }
      .tg-prem-price__addprice {
        display: block;
        color: #362b2b;
        font-size: 1rem;
        line-height: 1;
        font-weight: 400;
        margin: 0;
        padding: 0;
        opacity: 0.75;
        margin-bottom: 2rem; }
      .tg-prem-price-btn {
        display: flex;
        align-items: center;
        background: transparent;
        border: 1px solid rgba(255, 72, 23, 0.25);
        background: rgba(255, 72, 23, 0.05);
        border-radius: 0.5rem;
        padding: 1rem 1.25rem;
        transition: all 0.15s linear; }
        .tg-prem-price-btn__text {
          color: #362b2b;
          font-size: 1.25rem;
          font-weight: 400;
          margin: 0;
          padding: 0;
          transition: all 0.15s linear; }
        .tg-prem-price-btn__icon {
          color: #ff4817;
          font-size: 1.5rem;
          margin-left: 0.25rem;
          transition: all 0.15s linear; }
        .tg-prem-price-btn:hover {
          border: 1px solid #ff4817;
          background: #ff4817;
          cursor: pointer; }
          .tg-prem-price-btn:hover .tg-prem-price-btn__text {
            color: #fff; }
          .tg-prem-price-btn:hover .tg-prem-price-btn__icon {
            color: rgba(255, 255, 255, 0.5); }
    .tg-prem-help {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 0; }
      .tg-prem-help__lead {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 1.125rem;
        font-weight: 400;
        text-align: center; }
        .tg-prem-help__lead a {
          color: #ff4817;
          word-break: keep-all;
          margin-left: 0.5rem; }

body.navigator .header {
  background: white; }
  body.navigator .header__insta {
    border-left-color: #d2e2de; }
    body.navigator .header__insta .icon {
      color: #a4c4bc; }
  body.navigator .header-clients {
    border-right-color: #d2e2de; }
    body.navigator .header-clients__head, body.navigator .header-clients__sub {
      color: #85b0a5; }
  body.navigator .header-menu {
    border-top-color: #d2e2de;
    transition: all 0.2s ease-in-out; }
  body.navigator .header__btn {
    border-color: #669c8e;
    color: #669c8e; }
    body.navigator .header__btn:before {
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); }
    body.navigator .header__btn:hover {
      color: #fff;
      border-color: #ac71de;
      background: #ac71de; }
  @media (max-width: 991.98px) {
    body.navigator .header.open {
      background: #fff;
      box-shadow: 0 1rem 1.5rem -0.75rem rgba(81, 126, 114, 0.6); }
      body.navigator .header.open .header-logo .light {
        fill: #e9ecef; }
      body.navigator .header.open .header__insta svg {
        fill: #dee2e6; }
      body.navigator .header.open .header__insta:hover svg {
        fill: #669c8e; }
      body.navigator .header.open .header-menu {
        border-top-color: #e9ecef; } }

.navigator__head {
  display: block;
  color: #669c8e;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem; }

.navigator__img {
  user-select: none;
  pointer-events: none;
  display: block;
  height: 4rem;
  width: auto;
  margin-left: 0;
  margin-bottom: 1.5rem; }

.navigator__lead {
  display: block;
  position: relative;
  z-index: 1;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0; }
  .navigator__lead a {
    color: #669c8e;
    border-bottom: 1px solid rgba(102, 156, 142, 0);
    transition: all 0.2s ease-in-out; }
    .navigator__lead a:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ac71de;
      border-bottom: 1px solid rgba(172, 113, 222, 0.23); }

.navigator-tabs {
  background: #fff;
  border-radius: 50rem;
  border: none;
  padding: 0.35rem;
  margin-top: 1rem; }
  .navigator-tabs .nav-item {
    margin-top: 0;
    margin-bottom: 0; }
  .navigator-tabs .nav-link {
    color: #669c8e;
    background-color: transparent;
    border: none;
    border-radius: 50rem; }
    .navigator-tabs .nav-link.active {
      color: #fff;
      background-color: #669c8e; }

.navigator-hero {
  background: linear-gradient(white, #fff); }
  .navigator-hero__wrap {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (min-width: 576px) {
      .navigator-hero__wrap {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .navigator-hero__wrap {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .navigator-hero__wrap {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .navigator-hero__wrap {
        max-width: 1140px; } }
    @media (min-width: 1366px) {
      .navigator-hero__wrap {
        max-width: 1306px; } }
    @media (min-width: 1920px) {
      .navigator-hero__wrap {
        max-width: 1860px; } }
  .navigator-hero__head {
    display: block;
    color: #000;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .navigator-hero__head {
        font-size: 3.75rem; } }
  .navigator-hero__sub {
    display: block;
    color: #669c8e;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0; }
  .navigator-hero__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem; }

.navigator-count {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.navigator-div {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 2.5rem 1.5rem;
  border: 1px solid #c3d8d3;
  border-radius: 0.5rem; }
  .navigator-div--center {
    align-items: center; }
  .navigator-div:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(-45deg, rgba(102, 156, 142, 0.05), rgba(102, 156, 142, 0));
    z-index: 0;
    width: 100%;
    height: 100%; }

.navigator-form {
  background: #669c8e;
  background: linear-gradient(45deg, #669c8e, #2a4847);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem; }
  @media (max-width: 991.98px) {
    .navigator-form {
      padding: 2rem 1rem;
      text-align: center; } }
  .navigator-form .navigator__sub {
    color: rgba(255, 255, 255, 0.8);
    display: block;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    max-width: 80%; }
    @media (max-width: 991.98px) {
      .navigator-form .navigator__sub {
        max-width: 100%; } }
  .navigator-form .navigator__head {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.25rem; }
    @media (max-width: 991.98px) {
      .navigator-form .navigator__head {
        font-size: 1.875rem; } }
  .navigator-form__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
  .navigator-form__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .navigator-form__bot .navigator-form__item {
      width: 92%;
      margin-right: 0;
      margin-bottom: 1rem; }
  .navigator-form__item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-navigatort;
    width: 10rem;
    margin-right: 1rem; }
    @media (max-width: 991.98px) {
      .navigator-form__item {
        width: 5rem; } }
    .navigator-form__item-last {
      width: 12rem;
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .navigator-form__item-last {
          width: 7rem; } }
  .navigator-form__label {
    display: block;
    color: #a4c4bc;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem; }
  .navigator-form__input {
    display: block;
    position: relative;
    max-width: 100%;
    color: #000;
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    border: 2px solid rgba(255, 193, 7, 0);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    padding: 1rem 0.75rem;
    text-align: center;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .navigator-form__input {
        font-size: 1.875rem; } }
    .navigator-form__input:focus, .navigator-form__input:active {
      outline: none;
      border: 2px solid #ffc107; }
  .navigator-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #ffe600;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out; }
    .navigator-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .navigator-form__btn:hover {
      color: #000;
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 2.5rem 2rem -1rem rgba(41, 64, 58, 0.6); }
      .navigator-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .navigator-form__btn:focus, .navigator-form__btn:active {
      outline: none; }
  .navigator-form__help {
    display: block;
    color: #a4c4bc;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    text-align: center;
    padding: 0; }
    .navigator-form__help a {
      color: #a4c4bc;
      text-decoration: none;
      border-bottom: 1px solid rgba(164, 196, 188, 0.23);
      transition: all 0.2s ease-in-out; }
      .navigator-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.navigator-contact {
  background: #e8f0ee;
  border-radius: 0.5rem;
  padding: 2rem 1.5rem; }

.navigator-result {
  padding-bottom: 4rem; }
  .navigator-result-hero {
    background: #669c8e;
    padding-top: 4rem; }
    .navigator-result-hero__back {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-navigatort; }
      .navigator-result-hero__back:hover {
        text-decoration: none; }
        .navigator-result-hero__back:hover .navigator-result-hero__arr {
          fill: #a4c4bc; }
        .navigator-result-hero__back:hover .navigator-result-hero__link {
          text-decoration: none;
          color: #a4c4bc; }
    .navigator-result-hero__arr {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      fill: #fff;
      transition: all 0.2s ease-in-out; }
    .navigator-result-hero__link {
      display: block;
      text-decoration: none;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out; }
    .navigator-result-hero__logo {
      display: block;
      width: 10rem;
      height: auto;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .navigator-result-hero__logo {
          width: 8rem; } }
      @media (max-width: 1199.98px) {
        .navigator-result-hero__logo {
          margin-left: auto;
          margin-right: 0; } }
    .navigator-result-hero__top {
      display: block;
      background: #fff;
      width: 100%;
      margin: 0;
      padding: 0;
      color: #000;
      font-size: 1.40625rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      padding: 2rem 1rem;
      margin-top: 2rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
  .navigator-result__numbers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap; }
    .navigator-result__numbers .letter,
    .navigator-result__numbers .num {
      display: block;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding: 0; }
    .navigator-result__numbers .letter {
      color: #adb5bd;
      margin-right: 0.25rem; }
    .navigator-result__numbers .num {
      color: #669c8e;
      font-weight: 700;
      margin-right: 1rem; }
      .navigator-result__numbers .num:last-child {
        margin-right: 0; }
  .navigator-result__sub {
    display: block;
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem; }
    .navigator-result__sub strong {
      font-weight: 700;
      color: #669c8e; }
  .navigator-result__list {
    color: #000;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1rem; }
  .navigator-result__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; }
    .navigator-result__group:last-child {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    @media (max-width: 991.98px) {
      .navigator-result__group {
        flex-direction: column; } }
  .navigator-result__btn {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #669c8e;
    background: #fff;
    border: 2px solid #c3d8d3;
    box-shadow: 0 0 0 0 rgba(102, 156, 142, 0);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 1.25rem;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out; }
    .navigator-result__btn svg {
      user-select: none;
      pointer-events: none;
      display: block;
      height: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      fill: #669c8e; }
    .navigator-result__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .navigator-result__btn:hover {
      color: #fff;
      background: #669c8e;
      border-color: #669c8e;
      text-decoration: none;
      transform: translateY(-4px);
      box-shadow: 0 1rem 2rem -1rem rgba(102, 156, 142, 0.6); }
      .navigator-result__btn:hover svg {
        fill: #a4c4bc; }
      .navigator-result__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .navigator-result__btn:focus, .navigator-result__btn:active {
      outline: none; }
  .navigator-result__step {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .navigator-result__step .step {
      background: #fff;
      display: inline-block;
      color: #669c8e;
      border: 2px solid #c3d8d3;
      border-radius: 50rem;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 1rem; }
    .navigator-result__step .navigator-result__sub {
      font-size: 1.125rem; }
  .navigator-result__img {
    user-select: none;
    pointer-events: none;
    display: block;
    max-width: 100%;
    height: auto;
    padding: 1rem; }
  .navigator-result .video {
    display: block;
    margin: 0 auto; }

.sigil-hero {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  @media (max-width: 991.98px) {
    .sigil-hero {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  .sigil-hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding-top: 10rem;
    padding-bottom: 10rem;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .sigil-hero-content {
        padding-top: 5rem;
        padding-bottom: 5rem; } }
    .sigil-hero-content:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -3;
      border-radius: 1rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #032a1c; }
    .sigil-hero-content:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -2;
      border-radius: 1rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent, rgba(38, 237, 165, 0.25)); }
  .sigil-hero__bg {
    display: block;
    position: absolute;
    z-index: -2;
    width: 120%;
    height: auto;
    opacity: 0.05;
    transform: rotate(-12deg);
    user-select: none;
    pointer-events: none; }
    @media (max-width: 991.98px) {
      .sigil-hero__bg {
        width: 300%; } }
  .sigil-hero-sigil {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
    position: absolute;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    opacity: 0;
    transition: all 0.15s linear; }
    .sigil-hero-sigil.animating {
      animation-duration: 3s;
      animation-name: fadein; }

@keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.125; }
  100% {
    opacity: 0; } }
    .sigil-hero-sigil__inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: auto;
      fill: #26EDA5; }
  .sigil-hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .sigil-hero-text__head {
      color: #fff;
      font-size: 10rem;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem; }
      @media (max-width: 991.98px) {
        .sigil-hero-text__head {
          font-size: 3.75rem; } }
    .sigil-hero-text__sub {
      display: block;
      color: #26EDA5;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding: 0;
      text-align: center; }
      @media (max-width: 991.98px) {
        .sigil-hero-text__sub {
          font-size: 1.5rem;
          line-height: 1.5rem; } }

.sigil-item {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: 1px solid rgba(130, 160, 149, 0.25);
  border-radius: 1rem;
  padding: 2rem;
  height: 100%; }
  @media (max-width: 991.98px) {
    .sigil-item {
      padding: 2rem 1.5rem; } }
  .sigil-item-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .sigil-item-header {
        margin-bottom: 0.5rem; } }
    .sigil-item-header__img {
      height: 2.5rem;
      width: auto;
      user-select: none;
      pointer-events: none;
      margin-right: 1rem; }
      @media (max-width: 991.98px) {
        .sigil-item-header__img {
          height: 2rem;
          margin-right: 0.75rem; } }
    .sigil-item-header__head {
      color: #05422c;
      font-size: 2rem;
      margin: 0;
      line-height: 2.25rem;
      padding: 0; }
      @media (max-width: 991.98px) {
        .sigil-item-header__head {
          font-size: 1.5rem; } }
  .sigil-item__lead {
    color: #82a095;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0; }
    .sigil-item__lead a {
      color: #82a095;
      text-decoration: none;
      transition: all 0.15s linear; }
      .sigil-item__lead a:hover {
        color: #507a6b; }

.sigil-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #05422c;
  border-radius: 1rem;
  padding: 3rem 2rem;
  margin-bottom: 2rem; }
  @media (max-width: 991.98px) {
    .sigil-form {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .sigil-form-gift {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px dashed rgba(255, 255, 255, 0.25);
    border-radius: 0.5rem;
    padding: 2rem;
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 1rem;
    transition: all 0.15s linear;
    cursor: pointer;
    position: relative;
    cursor: pointer; }
    .sigil-form-gift-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
      cursor: pointer; }
      .sigil-form-gift-checkbox:checked + label:before {
        border-color: #26EDA5;
        background-color: #26EDA5;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }
      .sigil-form-gift-checkbox + label {
        display: flex;
        flex-direction: column;
        align-items: center;
        user-select: none;
        margin-bottom: 0;
        cursor: pointer; }
        .sigil-form-gift-checkbox + label small {
          font-size: 1rem;
          font-weight: 400;
          opacity: 0.5; }
        .sigil-form-gift-checkbox + label:before {
          cursor: pointer;
          content: "";
          margin-bottom: 1rem;
          display: inline-block;
          width: 3rem;
          height: 3rem;
          flex-shrink: 0;
          flex-grow: 0;
          border: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 0.375rem;
          margin-right: 0.5rem;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%; }
    .sigil-form-gift:hover {
      color: #26EDA5; }
    .sigil-form-gift-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: inset 0 0 0 1.5px #ffc107;
      border-radius: 1rem;
      width: 70%;
      position: relative;
      opacity: 0;
      z-index: -1;
      height: 0;
      transition: all 0.15s linear;
      padding: 0;
      overflow: hidden; }
      @media (max-width: 991.98px) {
        .sigil-form-gift-form {
          width: 100%; } }
      .sigil-form-gift-form-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffc107;
        padding: 1.5rem 1rem;
        margin-bottom: 2rem;
        width: 100%; }
        .sigil-form-gift-form-badge__icon {
          color: #dc3545;
          font-size: 1.5rem;
          margin-right: 0.375rem; }
        .sigil-form-gift-form-badge__text {
          color: #343a40;
          font-size: 1rem;
          font-weight: 500; }
      .sigil-form-gift-form-lead {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%; }
        .sigil-form-gift-form-lead__add {
          display: block;
          color: #fff;
          text-align: center;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.5rem;
          border-top: 1px solid #ffc107;
          border-bottom: 1px solid #ffc107;
          margin: 2rem 0;
          padding: 2rem 1rem; }
          .sigil-form-gift-form-lead__add strong {
            border-bottom: 2px solid #26EDA5; }
          .sigil-form-gift-form-lead__add-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 1rem;
            position: relative; }
            .sigil-form-gift-form-lead__add-checkbox-checkbox {
              position: absolute;
              z-index: -1;
              opacity: 0;
              cursor: pointer; }
              .sigil-form-gift-form-lead__add-checkbox-checkbox:checked + label:before {
                border-color: #26EDA5;
                background-color: #26EDA5;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }
              .sigil-form-gift-form-lead__add-checkbox-checkbox + label {
                display: inline-flex;
                align-items: center;
                user-select: none;
                margin-bottom: 0;
                cursor: pointer; }
                .sigil-form-gift-form-lead__add-checkbox-checkbox + label:before {
                  cursor: pointer;
                  content: "";
                  display: inline-block;
                  width: 1.75rem;
                  height: 1.75rem;
                  flex-shrink: 0;
                  flex-grow: 0;
                  border: 1px solid rgba(255, 255, 255, 0.25);
                  border-radius: 0.375rem;
                  margin-right: 0.5rem;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: 50% 50%; }
        .sigil-form-gift-form-lead__lead {
          color: #fff;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 1.5rem;
          text-align: center;
          padding-left: 1rem;
          padding-right: 1rem;
          margin-bottom: 2rem; }
        .sigil-form-gift-form-lead-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 1rem;
          padding-right: 1rem; }
          .sigil-form-gift-form-lead-list__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            background: rgba(255, 255, 255, 0.05);
            box-shadow: inset 0 0 0 1px #26EDA5;
            border-radius: 0.5rem;
            margin-bottom: 1rem;
            padding: 1.5rem 2rem;
            color: #fff;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.5rem; }
            .sigil-form-gift-form-lead-list__item small {
              font-size: 0.75rem;
              line-height: 1rem;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.5);
              margin-top: 0.5rem; }
            .sigil-form-gift-form-lead-list__item-list {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              flex-wrap: wrap;
              margin-top: 2rem; }
              .sigil-form-gift-form-lead-list__item-list__item {
                flex: 0 0 calc(25% - 1rem);
                max-width: calc(25% - 1rem);
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                position: relative; }
                @media (max-width: 991.98px) {
                  .sigil-form-gift-form-lead-list__item-list__item {
                    flex: 0 0 calc(50% - 1rem);
                    max-width: calc(50% - 1rem); } }
                .sigil-form-gift-form-lead-list__item-list__item input {
                  position: absolute;
                  z-index: -1;
                  opacity: 0;
                  cursor: pointer; }
                  .sigil-form-gift-form-lead-list__item-list__item input:checked + label {
                    box-shadow: inset 0 0 0 2px #26EDA5; }
                    .sigil-form-gift-form-lead-list__item-list__item input:checked + label:after {
                      opacity: 1; }
                  .sigil-form-gift-form-lead-list__item-list__item input + label {
                    cursor: pointer;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    user-select: none;
                    border-radius: 0.5rem;
                    overflow: hidden;
                    position: relative;
                    box-shadow: inset 0 0 0 0 #26EDA5; }
                    .sigil-form-gift-form-lead-list__item-list__item input + label:after {
                      content: 'Выбрана';
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      position: absolute;
                      z-index: -2;
                      top: auto;
                      left: auto;
                      right: auto;
                      bottom: auto;
                      font-size: 1.25rem;
                      color: #000;
                      background: rgba(38, 237, 165, 0.75);
                      width: 100%;
                      height: 100%;
                      opacity: 0; }
                .sigil-form-gift-form-lead-list__item-list__item img {
                  user-select: none;
                  pointer-events: none;
                  max-width: 100%;
                  position: relative;
                  z-index: -2; }
      .sigil-form-gift-form__inputs {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2rem; }
        .sigil-form-gift-form__inputs .sigil-form-item {
          width: 100%;
          margin-bottom: 1rem; }
      .sigil-form-gift-form.visible {
        opacity: 1;
        z-index: 1;
        height: auto; }
  .sigil-form__head {
    color: #fff;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .sigil-form__head {
        font-size: 2rem;
        line-height: 1.75rem; } }
  .sigil-form__sub {
    color: #55f1b9;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .sigil-form__sub {
        font-size: 1.25rem;
        line-height: 1.5rem; } }
  .sigil-form-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 50%; }
    @media (max-width: 991.98px) {
      .sigil-form-item {
        width: 100%; } }
    .sigil-form-item__label {
      color: rgba(255, 255, 255, 0.25);
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1;
      margin: 0 0 1rem; }
    .sigil-form-item__input {
      width: 100%;
      color: #000;
      font-size: 3.75rem;
      font-weight: 400;
      line-height: 1;
      background: #fff;
      border: none;
      box-shadow: 0 0 0 transparent;
      border-radius: 0.5rem;
      margin: 0;
      padding: 1rem 0.75rem;
      text-align: center;
      transition: all 0.2s ease-in-out; }
      @media (max-width: 991.98px) {
        .sigil-form-item__input {
          font-size: 1.875rem; } }
      .sigil-form-item__input:focus, .sigil-form-item__input:active {
        outline: none;
        box-shadow: 0 0 1rem #26EDA5; }
  .sigil-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #26EDA5;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    transition: all 0.2s ease-in-out; }
    .sigil-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .sigil-form__btn:hover {
      color: #000;
      text-decoration: none;
      box-shadow: 0 0.25rem 2rem rgba(38, 237, 165, 0.6); }
      .sigil-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .sigil-form__btn:focus, .sigil-form__btn:active {
      outline: none; }
  .sigil-form__help {
    display: block;
    color: #a0dbc5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
    padding: 0; }
    .sigil-form__help a {
      color: #a0dbc5;
      text-decoration: none;
      border-bottom: 1px solid rgba(160, 219, 197, 0.23);
      transition: all 0.2s ease-in-out; }
      .sigil-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }

.sigil-result-hero {
  background: #05422c;
  padding-top: 4rem; }
  .sigil-result-hero__back {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start; }
    .sigil-result-hero__back:hover {
      text-decoration: none; }
      .sigil-result-hero__back:hover .sigil-result-hero__arr {
        fill: #26EDA5; }
      .sigil-result-hero__back:hover .sigil-result-hero__link {
        text-decoration: none;
        color: #26EDA5; }
  .sigil-result-hero__arr {
    user-select: none;
    pointer-events: none;
    display: block;
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
    fill: #fff;
    transition: all 0.2s ease-in-out; }
  .sigil-result-hero__link {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out; }
  .sigil-result-hero__logo {
    display: block;
    width: 10rem;
    height: auto;
    margin: 0 auto; }
    @media (max-width: 991.98px) {
      .sigil-result-hero__logo {
        width: 8rem; } }
    @media (max-width: 1199.98px) {
      .sigil-result-hero__logo {
        margin-left: auto;
        margin-right: 0; } }
  .sigil-result-hero__top {
    display: block;
    background: #fff;
    width: 100%;
    color: #000;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    padding: 2rem 1rem;
    margin: 2rem 0 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }

.sigil-result-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem; }

.sigil-result-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  width: 50%;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 1px rgba(130, 160, 149, 0.25);
  position: relative;
  z-index: 1; }
  .sigil-result-img:before {
    content: '';
    display: block;
    padding-top: 100%; }
  .sigil-result-img__img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.sigil-result__group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem; }

.sigil-result__btn {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  color: #fff;
  background: #05422c;
  box-shadow: 0 0 0 0 rgba(38, 237, 165, 0);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out; }
  .sigil-result__btn:hover {
    color: #000;
    background: #26EDA5;
    text-decoration: none;
    box-shadow: 0 1rem 2rem -1rem rgba(38, 237, 165, 0.6); }
  .sigil-result__btn:focus, .sigil-result__btn:active {
    outline: none; }

.shop-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem; }
  .shop-head__title {
    display: inline-flex;
    color: #09302b;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.375rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 1; }
    @media (max-width: 1199.98px) {
      .shop-head__title {
        font-size: 1.5rem; } }
    .shop-head__title:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 0.25rem;
      background-color: #17d1b8;
      bottom: -0.75rem;
      left: 0; }

.shop-hero__content {
  display: flex;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
  margin-bottom: 5rem;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(23, 209, 184, 0.125);
  border-radius: 1rem; }

.shop-hero__logo {
  height: 7rem;
  margin: 0 auto;
  user-select: none;
  pointer-events: none; }
  @media (max-width: 991.98px) {
    .shop-hero__logo {
      width: 100%;
      height: auto; } }

.shop-hero__bg {
  opacity: 0.025;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%); }

.shop-products {
  margin-bottom: 5rem; }

.shop-product {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: inset 0 0 0 1px rgba(9, 48, 43, 0.125);
  padding: 2rem;
  position: relative;
  margin-bottom: 1rem; }
  .shop-product__img {
    height: auto;
    width: 60%;
    user-select: none;
    pointer-events: none;
    margin: 0 auto 2rem; }
  .shop-product__title {
    color: #09302b;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1rem;
    margin-bottom: 1rem; }
  .shop-product-description {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    margin-bottom: 1rem; }
    .shop-product-description__item {
      color: #849895;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin-bottom: 0.5rem; }
      .shop-product-description__item strong {
        color: #17d1b8;
        font-weight: 500; }
      .shop-product-description__item:last-child {
        margin-bottom: 0; }
      .shop-product-description__item--add {
        width: 100%;
        background-color: rgba(23, 209, 184, 0.125);
        border-radius: 0.5rem;
        padding: 1rem; }
  .shop-product-content {
    width: 100%;
    padding-top: 1rem;
    margin-top: 0.5rem;
    border-top: 1px solid rgba(9, 48, 43, 0.125);
    color: #849895;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem; }
    .shop-product-content__badge {
      color: #332b00;
      background: #FFD700;
      border-radius: 50rem;
      padding: 0 0.5rem; }
      .shop-product-content__badge.silver {
        color: #5a5a5a;
        background: #C0C0C0; }

.shop-list {
  background-color: rgba(23, 209, 184, 0.125);
  padding-top: 5rem;
  padding-bottom: 1rem;
  border-radius: 1rem; }

.shop-item {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  background-color: white;
  border-radius: 0.75rem;
  padding: 2rem;
  position: relative;
  margin-bottom: 1rem; }
  .shop-item--unavailable {
    pointer-events: none;
    user-select: none;
    overflow: hidden; }
    .shop-item--unavailable:before {
      content: 'Товар недоступен';
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1rem;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0.75rem 1.25rem;
      border-radius: 50rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 2; }
    .shop-item--unavailable:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(3px); }
  .shop-item__badge {
    color: white;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    background-color: #f5576c;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 1rem;
    right: 1rem; }
  .shop-item__img {
    height: auto;
    width: 100%;
    user-select: none;
    pointer-events: none;
    margin-bottom: 1rem;
    border-radius: 0.5rem; }
  .shop-item__title {
    color: #09302b;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1rem;
    margin-bottom: 2rem; }
  .shop-item__description {
    color: #849895;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .shop-item-prices {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.75rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba(9, 48, 43, 0.125); }
    .shop-item-prices-col {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .shop-item-prices-col__title {
        color: #849895;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1rem; }
      .shop-item-prices-col__main {
        color: #09302b;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: 1px;
        margin-bottom: 0.5rem; }
      .shop-item-prices-col__item {
        color: rgba(9, 48, 43, 0.25);
        font-size: 0.825rem;
        font-weight: 400;
        line-height: 1rem;
        margin-bottom: 0.25rem; }
        .shop-item-prices-col__item:last-child {
          margin-bottom: 0; }
  .shop-item-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-color: #17d1b8;
    border: none;
    border-radius: 0.75rem;
    box-shadow: rgba(23, 209, 184, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.15s linear;
    position: relative;
    user-select: none;
    padding: 1rem; }
    .shop-item-btn:focus, .shop-item-btn:active {
      outline: none; }
    .shop-item-btn:hover {
      background-color: #33e8d0;
      box-shadow: rgba(23, 209, 184, 0.25) 0 30px 40px -5px, rgba(0, 0, 0, 0.3) 0px 10px 15px -8px; }
    .shop-item-btn__icon {
      color: #0d6055;
      font-size: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); }
    .shop-item-btn__text {
      color: #09302b;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1rem; }

.monthly-hero {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  @media (max-width: 991.98px) {
    .monthly-hero {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  .monthly-hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .monthly-hero-content {
        padding-top: 5rem;
        padding-bottom: 5rem; } }
  .monthly-hero__img {
    user-select: none;
    pointer-events: none;
    height: 30rem;
    width: auto;
    margin-bottom: 1rem; }
  .monthly-hero__title {
    color: #000;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    max-width: 60%; }
    @media (max-width: 991.98px) {
      .monthly-hero__title {
        font-size: 1.5625rem;
        max-width: 100%; } }
  .monthly-hero__description {
    color: #82a095;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75rem;
    margin-bottom: 0;
    text-align: center;
    max-width: 70%; }
    @media (max-width: 991.98px) {
      .monthly-hero__description {
        font-size: 1rem;
        line-height: 1.25rem;
        max-width: 100%; } }

.monthly-item {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: 1px solid rgba(130, 160, 149, 0.25);
  border-radius: 1rem;
  padding: 2rem; }
  @media (max-width: 991.98px) {
    .monthly-item {
      padding: 2rem 1.5rem; } }
  .monthly-item-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .monthly-item-header {
        margin-bottom: 0.5rem; } }
    .monthly-item-header__img {
      height: 2.5rem;
      width: auto;
      user-select: none;
      pointer-events: none;
      margin-right: 1rem; }
      @media (max-width: 991.98px) {
        .monthly-item-header__img {
          height: 2rem;
          margin-right: 0.75rem; } }
    .monthly-item-header__head {
      color: #05422c;
      font-size: 2rem;
      margin: 0;
      line-height: 2.25rem;
      padding: 0; }
      @media (max-width: 991.98px) {
        .monthly-item-header__head {
          font-size: 1.5rem; } }
  .monthly-item__lead {
    color: #82a095;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0; }
    .monthly-item__lead a {
      color: #82a095;
      text-decoration: none;
      transition: all 0.15s linear; }
      .monthly-item__lead a:hover {
        color: #507a6b; }

.monthly-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0da06b;
  border-radius: 1rem;
  padding: 3rem 2rem;
  margin-bottom: 2rem; }
  @media (max-width: 991.98px) {
    .monthly-form {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .monthly-form__head {
    color: #fff;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .monthly-form__head {
        font-size: 2rem;
        line-height: 1.75rem; } }
  .monthly-form__sub {
    color: #55f1b9;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .monthly-form__sub {
        font-size: 1.25rem;
        line-height: 1.5rem; } }
  .monthly-form-help {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-top: 4rem;
    color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5); }
    @media (max-width: 991.98px) {
      .monthly-form-help {
        width: 100%; } }
  .monthly-form-gift {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 4rem;
    padding: 2rem;
    background: #fff;
    border-radius: 1rem; }
    @media (max-width: 991.98px) {
      .monthly-form-gift {
        flex-direction: column; } }
    .monthly-form-gift__text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: calc(60% - 1rem);
      margin-right: 1rem; }
      @media (max-width: 991.98px) {
        .monthly-form-gift__text {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem; } }
    .monthly-form-gift__title {
      color: #000;
      font-size: 2rem;
      margin: 0;
      line-height: 2.25rem;
      padding: 0;
      margin-bottom: 0.5rem; }
      @media (max-width: 991.98px) {
        .monthly-form-gift__title {
          font-size: 1.5rem; } }
    .monthly-form-gift__lead {
      color: #137753;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin-bottom: 0.25rem; }
      .monthly-form-gift__lead:last-child {
        margin-bottom: 0; }
    .monthly-form-gift-covers {
      display: flex;
      align-items: center;
      width: 40%; }
      @media (max-width: 991.98px) {
        .monthly-form-gift-covers {
          width: 100%; } }
      .monthly-form-gift-covers__img {
        display: block;
        width: calc(50% - 0.5rem);
        height: auto;
        position: relative;
        z-index: 1;
        user-select: none;
        pointer-events: none;
        border-radius: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.5); }
        .monthly-form-gift-covers__img:first-child {
          margin-left: 0; }
        .monthly-form-gift-covers__img:last-child {
          margin-right: 0; }
  .monthly-form-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 50%; }
    @media (max-width: 991.98px) {
      .monthly-form-item {
        width: 100%; } }
    .monthly-form-item__label {
      color: rgba(255, 255, 255, 0.25);
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1;
      margin: 0 0 1rem; }
    .monthly-form-item__input {
      width: 100%;
      color: #000;
      font-size: 3.75rem;
      font-weight: 400;
      line-height: 1;
      background: #fff;
      border: none;
      box-shadow: 0 0 0 transparent;
      border-radius: 0.5rem;
      margin: 0;
      padding: 1rem 0.75rem;
      text-align: center;
      transition: all 0.2s ease-in-out; }
      @media (max-width: 991.98px) {
        .monthly-form-item__input {
          font-size: 1.875rem; } }
      .monthly-form-item__input:focus, .monthly-form-item__input:active {
        outline: none;
        box-shadow: 0 0 1rem #26EDA5; }
    .monthly-form-item-row {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between; }
      .monthly-form-item-row .monthly-form-item {
        margin-right: 0.75rem; }
        .monthly-form-item-row .monthly-form-item:last-child {
          margin-right: 0; }
  .monthly-form__btn {
    display: block;
    position: relative;
    overflow: hidden;
    color: #000;
    background: #26EDA5;
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 1rem 1.5rem;
    border-radius: 25rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    transition: all 0.2s ease-in-out; }
    .monthly-form__btn:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(-92deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
      width: 3rem;
      height: 100%;
      transform: skewX(-42deg) translateX(-10%);
      z-index: 1;
      will-change: transform;
      opacity: 0;
      transition: all 0.3s ease; }
    .monthly-form__btn:hover {
      color: #000;
      text-decoration: none;
      box-shadow: 0 0.25rem 2rem rgba(38, 237, 165, 0.6); }
      .monthly-form__btn:hover:before {
        transform: skewX(-42deg) translateX(510%);
        opacity: 1; }
    .monthly-form__btn:focus, .monthly-form__btn:active {
      outline: none; }
  .monthly-form__help {
    display: block;
    color: #a0dbc5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
    padding: 0; }
    .monthly-form__help a {
      color: #a0dbc5;
      text-decoration: none;
      border-bottom: 1px solid rgba(160, 219, 197, 0.23);
      transition: all 0.2s ease-in-out; }
      .monthly-form__help a:hover {
        text-decoration: none;
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.23); }
