.payment {
    &__head {
        display: block;

        color: $black;
        font-size: $font-size-base * 1.25;
        font-weight: $font-weight-bold;
        line-height: 1;

        margin: 0;
        padding: 0;

        margin-bottom: 1rem;
    } // &__head

    &__top {
        display: block;

        background: $white;

        width: 100%;

        margin: 0;
        padding: 0;

        color: $black;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        line-height: 1;
        text-align: center;

        padding: 2rem 1rem;

        margin-top: 4rem;

        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    } // &__top

    &__bot {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        background: $white;

        width: 100%;

        margin: 0;
        padding: 0;

        color: $black;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        line-height: 1;
        text-align: center;

        padding: 2rem 1rem;

        margin-top: 0rem;
        margin-bottom: 4rem;

        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

        background: $gray-100;
    } // &__bot

    &-logo {
        display: block;
        width: 10rem;
        height: auto;

        margin: 0 auto;

        @include media-breakpoint-down(md) {
            width: 8rem;
        }

        @include media-breakpoint-down(lg) {
            margin-left: auto;
            margin-right: 0;
        }

        .dark {
            fill: $white;
        } // .dark

        .light {
            fill: lighten($pink, 10%);
        } // .light
    } // &__img

    &-hero {
        background: $pink;

        padding-top: 4rem;

        &__head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        } // &__head

        &__back {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;

            &:hover {
                text-decoration: none;
                .payment-hero__arr {
                    fill: opacity($white, 0.8);
                }
                .payment-hero__link {
                    text-decoration: none;
                    color: opacity($white, 0.8);
                }
            }
        } // &__back

        &__arr {
            @include no-select;

            display: block;

            height: 1rem;
            width: auto;

            margin-right: 0.5rem;

            fill: $white;
            transition: $transition-base;
        } // &__arr

        &__link {
            display: block;

            text-decoration: none;

            color: $white;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1;

            margin: 0;
            padding: 0;
            transition: $transition-base;
        }

        &__add {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-end;
        } // &__add

        &__img {
            @include no-select;

            display: block;
            height: 2rem;
            width: auto;

            margin: 0;
            padding: 0;
        } // &__img

        &__head {
            display: block;

            color: lighten($pink, 20%);
            font-size: $font-size-base;
            font-weight: $font-weight-base;

            margin: 0;
            padding: 0;
            margin-right: 1rem;
        } // &__span
    } // &-hero

    &-method {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 1rem 1.5rem;

        position: relative;
        user-select: none;

        &.disabled {
            @include no-select;
            .payment-method {
                &__div {
                    background: $gray-200;
                }
            }
        }

        &:hover {
            cursor: pointer;

            .payment-method__div {
                border: 2px solid $gray-400;
            }
        }

        &__input {
            position: absolute;
            opacity: 0;
            visibility: hidden;

            &:checked {
                ~ .payment-method__div {
                    border: 2px solid $pink;
                }
            }
        } // &__input

        &__div {
            position: absolute;

            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border: 2px solid $gray-200;
            border-radius: 0.5rem;

            transition: $transition-base;
        } // &__div

        &__img {
            @include no-select;

            display: block;
            z-index: 999;
            height: 2rem;
            width: auto;

        } // &__img
    } // &-method

    &-group {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-end;

        &.has-error {
            .payment-group__input {
                border-color: $red;
            }
        }

        &__label {
            display: block;

            color: $gray-600;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1;

            margin: 0;
            padding: 0;

            margin-bottom: 0.5rem;
        } // &__label

        &__input {
            display: block;
            width: 100%;

            background: $white;
            border: 2px solid $gray-200;
            border-radius: 0.5rem;

            padding: 1rem 0.75rem;

            margin: 0;
            margin-bottom: 2rem;

            transition: border linear .1s;

            &:focus,
            &:active {
                outline: none;
                border-color: $pink;
            }
        } // &__input

        &__error {
            display: block;

            color: $red;
            font-size: $font-size-sm;
            font-weight: $font-weight-base;
            line-height: 1;

            margin: 0;
            padding: 0;
            margin-top: -0.5rem;
        }
    } // &-group

    &__btn {
        display: block;
        position: relative;
        overflow: hidden;

        color: $white;
        background: $pink;
        border: none;

        box-shadow: 0 1rem 1rem -0.5rem opacity($pink, 0.3);

        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        line-height: 1;
        margin: 0;
        padding: 1rem 1.5rem;
        border-radius: 25rem;

        margin-bottom: 9rem;

        margin-left: auto;
        margin-right: auto;

        transition: $transition-base;

        &:before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            background: linear-gradient(
                -92deg,
                opacity($white, 0.7),
                opacity($white, 0.7)
            );

            width: 3rem;
            height: 100%;

            transform: skewX(-42deg) translateX(-10%);
            z-index: 1;
            will-change: transform;

            opacity: 0;

            transition: all 0.3s ease;
        } // &:before

        &:hover {
            color: $white;
            text-decoration: none;
            transform: translateY(-10px);

            box-shadow: 0 2.5rem 2rem -1rem opacity($pink, 0.6);

            &:before {
                transform: skewX(-42deg) translateX(510%);
                opacity: 1;
            } // &:before
        }

        &:focus,
        &:active {
            outline: none;
        }
    } // &__btn

    &-footer {
        margin-bottom: 2rem;

        &__img {
            @include no-select;

            display: block;

            height: 2rem;
            width: auto;

            margin: 0;
            padding: 0;
            margin-bottom: 1rem;
        } // &__img

        &__head {
            display: block;

            color: $gray-900;
            font-size: $font-size-base * 1.25;
            font-weight: $font-weight-base;
            line-height: 1;
            margin: 0;
            padding: 0;
            margin-bottom: 0.5rem;
        } // &__head

        &__sub {
            display: block;

            color: $gray-500;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 1.5;
            margin: 0;
            padding: 0;
        } // &__sub
    } // &-footer

} // .payment
