.footer {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &-wrap {
        @include make-container;
        @include make-container-max-widths;

        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }
    } // &-wrap


    &-copy {
        display: block;

        color: $gray-400;
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
        line-height: 1;

        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }

    } // &-copy

    &-link {
        display: block;

        text-decoration: none;

        color: $gray-400;
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
        line-height: 1;
        margin: 0;
        padding: 0;

        transition: $transition-base;

        &:hover {
            text-decoration: none;
            color: $purple;
        }

        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }

    } // &__link
} // .footer
